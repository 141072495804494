import { useTranslation } from 'react-i18next';

import { Chip } from 'components/mosaic/atoms/Chip';
import { theme } from 'components/providers/StyleProvider/theme';
import { LinusTooltip } from 'components/shared/LinusTooltip';
import { Caption, Icon, P1, P2 } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import { Trial } from '../BdstReport.types';

import {
	OrderWrapper,
	Row,
	Table,
	TableHeaderCell,
	TableRowCell,
	Tooltip,
} from './BdstTable.style';

interface BDSTTableProps {
	trialOne: Trial;
	trialTwo: Trial;
	trialThree: Trial;
}

export function BDSTTable({
	trialOne,
	trialTwo,
	trialThree,
}: Readonly<BDSTTableProps>) {
	const { t } = useTranslation();

	return (
		<Table>
			<thead>
				<tr>
					<TableHeaderCell index={0}>
						<P2
							style={{
								fontWeight: 500,
								color: theme.color.graphTextSecondary,
							}}
						>
							{t('web.report.bdstReport.breakdown')}
						</P2>
					</TableHeaderCell>
					<TableHeaderCell index={1}>
						<div
							style={{
								display: 'flex',
								gap: theme.spacing.xs,
								marginLeft: theme.spacing.lg,
							}}
						>
							<P1
								style={{
									fontWeight: 600,
								}}
							>
								{t('web.report.bdstReport.anyOrder')}
							</P1>
							<LinusTooltip
								overlay={
									<Tooltip>
										{t(
											'web.report.bdstReport.anyOrderRecall'
										)}
									</Tooltip>
								}
								tooltipIcon={
									<Icon
										icon={icons.InfoOutlined}
										iconWidth={24}
										iconHeight={24}
									/>
								}
							/>
						</div>
					</TableHeaderCell>
					<TableHeaderCell index={2}>
						<div
							style={{
								display: 'flex',
								gap: theme.spacing.xs,
								marginLeft: theme.spacing.lg,
							}}
						>
							<P1
								style={{
									fontWeight: 600,
								}}
							>
								{t('web.report.bdstReport.serialOrder')}
							</P1>
							<LinusTooltip
								overlay={
									<Tooltip>
										{t(
											'web.report.bdstReport.serialOrderRecall'
										)}
									</Tooltip>
								}
								tooltipIcon={
									<Icon
										icon={icons.InfoOutlined}
										iconWidth={24}
										iconHeight={24}
									/>
								}
							/>
						</div>
					</TableHeaderCell>
				</tr>
			</thead>
			<tbody>
				<Row index={0}>
					<TableRowCell
						index={0}
						style={{
							paddingLeft: 0,
						}}
					>
						<P1 style={{ fontWeight: 600 }}>
							{t('web.report.bdstReport.trial_1')}
							{': '}
							{t('web.report.bdstReport.backwardsDigitSpan')}
						</P1>
					</TableRowCell>
					<TableRowCell index={1}>
						<BDSTOrderField value={trialOne.any} />
					</TableRowCell>
					<TableRowCell index={2}>
						<BDSTOrderField value={trialOne.serial} />
					</TableRowCell>
				</Row>
				<Row index={1}>
					<TableRowCell
						index={0}
						style={{
							paddingLeft: 0,
						}}
					>
						<P1 style={{ fontWeight: 600 }}>
							{t('web.report.bdstReport.trial_2')}
							{': '}
							{t('web.report.bdstReport.backwardsDigitSpan')}
						</P1>
					</TableRowCell>
					<TableRowCell index={1}>
						<BDSTOrderField value={trialTwo.any} />
					</TableRowCell>
					<TableRowCell index={2}>
						<BDSTOrderField value={trialTwo.serial} />
					</TableRowCell>
				</Row>
				<Row index={2}>
					<TableRowCell
						index={0}
						style={{
							paddingLeft: 0,
						}}
					>
						<P1 style={{ fontWeight: 600 }}>
							{t('web.report.bdstReport.trial_3')}
							{': '}
							{t('web.report.bdstReport.backwardsDigitSpan')}
						</P1>
					</TableRowCell>
					<TableRowCell index={1}>
						<BDSTOrderField value={trialThree.any} />
					</TableRowCell>
					<TableRowCell index={2}>
						<BDSTOrderField value={trialThree.serial} />
					</TableRowCell>
				</Row>
			</tbody>
		</Table>
	);
}

interface BDSTOrderFieldProps {
	value?: string;
}

function BDSTOrderField({ value }: Readonly<BDSTOrderFieldProps>) {
	const { t } = useTranslation();

	const info = !value
		? t('web.report.bdstReport.canNotCalculate')
		: t('web.report.bdstReport.numbers');

	return (
		<OrderWrapper>
			<Chip label={value ?? '--'} />
			<Caption>{info}</Caption>
		</OrderWrapper>
	);
}
