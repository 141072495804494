/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

import {
	GetPatientsByOrgSortField,
	PatientApiGetPatientsByOrgRequest,
} from '@lh/eng-platform-subject-service-rest-client';

import { usePaginatedPatientsQuery } from 'api/patient/usePaginatedPatients';
import { SortProps } from 'components/shared/DataTable/PaginatedDataTable/pageHelpers';
import {
	getPaginatedPatientsFilterOptions,
	getPaginatedPatientsSortOptions,
} from './Patients.helpers';

export type GetPatientsTableDataInput = {
	organizationId: string;
	searchValue: string | null;
	page: PatientApiGetPatientsByOrgRequest['page'];
	pageSize: PatientApiGetPatientsByOrgRequest['pageSize'];
	sort: SortProps<GetPatientsByOrgSortField> | undefined | null;
};

export function usePatientsTableData(input: GetPatientsTableDataInput) {
	const organizationId = input?.organizationId;

	// Get the list of patients
	const {
		data: patients,
		isPending,
		refetch: reloadPatients,
	} = usePaginatedPatientsQuery({
		organizationId,
		page: input?.page,
		pageSize: input?.pageSize,
		...getPaginatedPatientsFilterOptions(input?.searchValue),
		...getPaginatedPatientsSortOptions(input?.sort),
	});

	const totalCount = useMemo(
		() => patients?.totalCount ?? 0,
		[patients?.totalCount]
	);

	return {
		data: patients,
		loading: isPending,
		reloadPatients,
		totalCount,
	};
}
