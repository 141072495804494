import {
	DeepSegmentResult,
	SegmentType,
} from '@lh/eng-platform-battery-service-rest-client';

import { useTranslation } from 'react-i18next';

import { LoadingLayout } from '../../../../../components/shared/ReportLoader';
import { Report } from '../../../../report/CognitiveEvaluation.types';
import { parseSegmentResult } from '../../../Report.utils';
import { FluencyLayout } from '../FluencyLayout';

import { useFluencyData } from './PhonemicLayout.utils';

export const EXAMPLE_SCORES = {
	correctResponses: '16',
	percentile: 88,
	breakdownScores: { epoch1: '8', epoch2: '4', epoch3: '3', epoch4: '1' },
	audio: {
		url: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
		prompt: 'Animals',
	},
};

type PhonemicLayoutProps = {
	batteryResult: Report;
	segmentResult?: DeepSegmentResult;
};

export function PhonemicLayout({
	segmentResult,
	batteryResult,
}: Readonly<PhonemicLayoutProps>) {
	const { t } = useTranslation();

	let _segmentResult: DeepSegmentResult;

	if (segmentResult) {
		_segmentResult = segmentResult;
	} else {
		_segmentResult = parseSegmentResult(
			SegmentType.PhonemicFluency,
			batteryResult
		);
	}

	const {
		audioPrompt,
		breakdownScores,
		correctResponses,
		percentile,
		recording,
		englishSegment,
	} = useFluencyData(_segmentResult);

	if (!segmentResult && !batteryResult) {
		return (
			<LoadingLayout
				title='web.report.phonemicFluencyReport.phonemic'
				tooltipText='web.report.phonemicFluencyReport.subTitle'
			/>
		);
	}

	const requiresReview = Number(correctResponses) < 0;

	return (
		<FluencyLayout
			title={t('web.report.phonemicFluencyReport.phonemic')}
			tooltipText={t('web.report.phonemicFluencyReport.subTitle')}
			correctResponses={correctResponses}
			percentile={percentile}
			requiresReview={requiresReview}
			breakdownScores={breakdownScores}
			englishSegment={englishSegment}
			audio={{
				url: recording,
				prompt: audioPrompt,
			}}
		/>
	);
}
