import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useTranslation } from 'react-i18next';

import { theme } from 'components/providers/StyleProvider/theme';
import { Caption } from 'components/shared/designSystem';
import { Auth as auth } from 'features/auth-service';
import { Errors } from '../AssessmentComponents/TrailsScoring/Errors';
import { TimeToComplete } from '../AssessmentComponents/TrailsScoring/TimeToComplete';
import { Report } from '../CognitiveEvaluation.types';
import {
	DrawingWidget,
	NORMALIZED_OVERLAY_STYLES,
} from '../SegmentComponents/ClockAnimation/DrawingWidget';

import { useFetchOverlayImage, useFetchTrailsRawData } from './Trails.hooks';
import {
	DisclaimerContainer,
	DrawingContainer,
	Image,
	Info,
	LineDivider,
	Title,
} from './TrailsLayout.style';
import { parseTrailsData } from './TrailsLayout.utils';

type TrailsLayoutProps = {
	data?: Report;
	segmentResult?: DeepSegmentResult;
};

export function TrailsLayout({
	data,
	segmentResult,
}: Readonly<TrailsLayoutProps>) {
	const { t } = useTranslation();

	const rawDataURL =
		data?.batteryResultById?.assessmentResults[0].segmentResults[0]
			.rawDataUrl ||
		segmentResult?.rawDataUrl ||
		'';

	const trailsRawData = useFetchTrailsRawData({
		auth,
		rawDataURL,
	});

	const trailsOverlayRef = useFetchOverlayImage({
		auth,
		rawDataURL,
		trailsRawData,
	});

	const trailsData = parseTrailsData(data, segmentResult);
	const shouldShowDrawingWidget =
		trailsRawData['trails']?.drawing?.data?.stroke_data?.length > 0;

	return (
		<div data-testid='trails-layout'>
			<Info data-testid='trails-info-section'>
				<TimeToComplete
					ageRange={trailsData.ageRange}
					duration={
						Math.floor(Number(trailsData.duration || 0) * 10) / 10
					}
					durationQuartile={trailsData.durationQuartile}
					durationColor={trailsData.durationColor}
					durationThreshold={trailsData.durationThreshold}
					maxTimeReached={trailsData.maxTimeReached}
				/>
				<Errors
					ageRange={trailsData.ageRange}
					totalMistakes={trailsData.totalMistakes}
					totalMistakesColor={trailsData.totalMistakesColor}
					errorsQuartile={trailsData.errorsQuartile}
					thresholds={trailsData.thresholds}
				/>
			</Info>
			<DrawingContainer data-testid='trails-drawing-section'>
				<Title data-testid='drawing-title'>
					{t('web.report.trailsReport.drawing')}
				</Title>
				<LineDivider />
				{shouldShowDrawingWidget && (
					<DrawingWidget
						data-testid='image-element'
						rawData={trailsRawData}
						section_name={trailsRawData['trails'].drawing.data.id}
						size='xl'
						overlay={
							<Image
								style={{
									height: NORMALIZED_OVERLAY_STYLES.xl.height,
								}}
								ref={trailsOverlayRef}
							/>
						}
					/>
				)}
			</DrawingContainer>
			<DisclaimerContainer data-testid='trails-disclaimer-section'>
				<div>
					<Caption color={theme.color.bodyTextSecondary}>
						{t('web.report.trailsReport.disclaimer_1')}
					</Caption>
				</div>
				<div>
					<Caption color={theme.color.bodyTextSecondary}>
						{t('web.report.trailsReport.disclaimer_2')}
					</Caption>
				</div>
			</DisclaimerContainer>
		</div>
	);
}
