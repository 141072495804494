import styled, { css } from 'styled-components';

import { Caption } from 'components/shared/designSystem';

export const Row = styled.tr<{ index: number }>(
	({ index, theme: { colors } }) => css`
		border-bottom: ${index === 2 ? 'unset' : `1px solid ${colors.gray_60}`};
	`
);
export const Table = styled.table`
	border-collapse: collapse;
	border-spacing: 0px;
	flex-grow: 1;
`;

export const TableCaption = styled(Caption)<{ index: number }>(
	({ index }) => css`
		font-weight: 600;
		margin-left: ${index > 0 ? '6px' : 'unset'};
	`
);

export const TableHeaderCell = styled.td<{ index: number }>(
	({ index, theme: { colors } }) => css`
		border-right: ${index === 2 ? 'unset' : `1px solid ${colors.gray_60}`};
		border-bottom: 1px solid ${colors.gray_40};
	`
);

export const TableRowCell = styled.td<{ index: number }>(
	({ index, theme: { colors } }) => css`
		border-right: ${index === 2 ? 'unset' : `1px solid ${colors.gray_60}`};
		padding: 10px 12px;
	`
);

export const OrderWrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.sm};
	`
);

export const Tooltip = styled.div(
	({ theme: { fontSize } }) => css`
		width: 500px;
		font-size: ${fontSize._14};
	`
);
