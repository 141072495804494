import { ScoreTag, ScoreWithInterpretation } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';

import { renderInterpretationComponent } from 'components/report/custom/components';

import { ScoreChipContainer } from './ScoreChip.style';

interface ScoreChipProps {
	score: string;
	quartile?: string;
	requiresReview?: boolean;
}

export function ScoreChip({
	score,
	quartile,
	requiresReview,
}: Readonly<ScoreChipProps>) {
	const { t } = useTranslation();

	const showInterpretation = requiresReview || quartile?.length;

	return (
		<ScoreChipContainer>
			{showInterpretation ? (
				<ScoreWithInterpretation
					score={{
						headerText: t(
							'web.report.uniqueResponses.correctResponses'
						).toUpperCase(),
						value: score,
					}}
					renderInterpretationText={() =>
						renderInterpretationComponent({
							quartile,
							requiresReview,
						})
					}
				/>
			) : (
				<ScoreTag
					title={t(
						'web.report.uniqueResponses.correctResponses'
					).toUpperCase()}
					score={score}
					roundedBorders
				/>
			)}
		</ScoreChipContainer>
	);
}
