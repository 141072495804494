import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { PatientApiGetPatientsByOrgRequest } from '@lh/eng-platform-subject-service-rest-client';

import { getPatientSubService } from 'api/getPatientSubService';
import { QueryKey } from 'api/query';

export const DEFAULT_PAGE_SIZE = 10;

export async function getPaginatedPatients(
	searchInput: PatientApiGetPatientsByOrgRequest,
	signal?: AbortSignal
) {
	if (!searchInput.organizationId) {
		throw new Error(
			"useGetPaginatedPatientsQuery: organizationId can't be null"
		);
	}

	const service = await getPatientSubService();
	const { data } = await service.getPatientsByOrg(searchInput, { signal });
	return data;
}

export function usePaginatedPatientsQuery(
	searchInput: PatientApiGetPatientsByOrgRequest
) {
	return useQuery({
		enabled: !!searchInput.organizationId,
		meta: {
			errorMessage: `Error fetching patients for organization ID: ${searchInput?.organizationId}`,
		},
		queryKey: [
			QueryKey.Patients,
			searchInput.organizationId,
			searchInput?.page,
			searchInput?.pageSize,
			searchInput?.sortOrder,
			searchInput?.sortField,
			searchInput?.filterValue,
			searchInput?.filterOperator,
		],
		queryFn: ({ signal }) => getPaginatedPatients(searchInput, signal),
		placeholderData: keepPreviousData,
		staleTime: 60 * 1000, // 1 minute,
	});
}
