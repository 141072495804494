import { useQuery } from '@tanstack/react-query';

import {
	BatteryResultState,
	GetBatteryResultsByParticipantFilterField,
	GetBatteryResultsByParticipantFilterOperator,
	GetBatteryResultsByParticipantSortField,
	GetBatteryResultsByParticipantSortOrder,
} from '@lh/eng-platform-battery-service-rest-client';

import { getBatteryService } from 'api/getBatteryService';
import { QueryKey } from 'api/query';

export async function getCompletedBatteryResult(
	participantId: string | null,
	signal?: AbortSignal
) {
	if (!participantId) {
		throw new Error(
			"getCompletedBatteryResults: participant ID can't be null"
		);
	}
	const service = await getBatteryService();
	const { data } = await service.getBatteryResultsByParticipant(
		{
			participantId,
			filterField: [
				GetBatteryResultsByParticipantFilterField.StatusContextState,
			],
			filterOperator: [GetBatteryResultsByParticipantFilterOperator.In],
			filterValue: [
				[
					BatteryResultState.AnalysisComplete,
					BatteryResultState.DataComplete,
					BatteryResultState.ParticipantComplete,
				].join(','),
			],
			sortField: [GetBatteryResultsByParticipantSortField.EndTime],
			sortOrder: [GetBatteryResultsByParticipantSortOrder.Desc],
			// Returning latest completed battery result
			pageSize: 1,
		},
		{ signal }
	);
	return data.results.at(0) ?? null;
}

export function useCompletedBatteryResultQuery(participantId: string | null) {
	return useQuery({
		enabled: !!participantId,
		meta: {
			errorMessage: `Error fetching battery results for Participant ID: ${participantId}`,
		},
		queryKey: [QueryKey.BatteryResults, participantId],
		queryFn: ({ signal }) =>
			getCompletedBatteryResult(participantId, signal),
	});
}
