import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useTranslation } from 'react-i18next';

import { Report } from '../../../CognitiveEvaluation.types';
import { FluencyLayout } from '../FluencyLayout';
import { LoadingLayout } from '../../../../shared/ReportLoader';

import { useSemanticData } from './SemanticLayout.utils';

export const EXAMPLE_SCORES = {
	correctResponses: '7',
	percentile: 4,
	breakdownScores: { epoch1: '1', epoch2: '3', epoch3: '5', epoch4: '7' },
	audio: {
		url: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
		prompt: 'Animals',
		recordingDuration: 0,
	},
};

type SemanticLayoutProps = {
	batteryResult?: Report;
	segmentResult?: DeepSegmentResult;
};

export function SemanticLayout({
	batteryResult,
	segmentResult,
}: Readonly<SemanticLayoutProps>) {
	const { t } = useTranslation();

	const {
		audio,
		breakdownScores,
		correctResponses,
		percentile,
		recordingUrl,
		englishSegment,
	} = useSemanticData({ batteryResult, segmentResult });

	if (!segmentResult && !batteryResult) {
		return (
			<LoadingLayout
				title='web.report.semanticFluencyReport.semantic'
				tooltipText='web.report.semanticFluencyReport.subTitle'
			/>
		);
	}

	const requiresReview = Number(correctResponses) < 0;

	return (
		<FluencyLayout
			data-testid='fluency'
			title={t('web.report.semanticFluencyReport.semantic')}
			tooltipText={t('web.report.semanticFluencyReport.subTitle')}
			correctResponses={correctResponses}
			percentile={percentile}
			requiresReview={requiresReview}
			breakdownScores={breakdownScores}
			englishSegment={englishSegment}
			audio={{
				url: recordingUrl,
				prompt: audio.prompt,
				recordingDuration: audio.recordingDuration,
			}}
		/>
	);
}
