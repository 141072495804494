import { ScorerLoader } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';

import { ReportInfoPanel } from 'components/report/AssessmentComponents/ReportCardInfo';

import { Card, Container, ScoreContainer } from './LoadingLayout.style';

type Props = {
	title: string;
	tooltipText?: string;
};

export function LoadingLayout({ title, tooltipText }: Readonly<Props>) {
	const { t } = useTranslation();
	return (
		<Container>
			<ReportInfoPanel
				title={t(title)}
				tooltipText={tooltipText ? t(tooltipText) : undefined}
			>
				<ScoreContainer loading={true}>
					<ScorerLoader />
				</ScoreContainer>
			</ReportInfoPanel>
			<Card>
				<ScorerLoader />
			</Card>
		</Container>
	);
}
