import styled from 'styled-components';

import { AudioWidget } from '../../shared/AudioWidget';
import { StyledCard } from '../../shared/StyledCard';
import { P1, P2 } from '../../shared/designSystem/TextComponents';

export type AudioPlaybackCardProps = {
	cardTitle: string;
	renderCardContent?: () => string | React.ReactNode;
	audioSource: string;
};

export const AudioPlaybackCard = ({
	cardTitle,
	renderCardContent,
	audioSource,
}: AudioPlaybackCardProps) => {
	return (
		<Card>
			<Header data-testid={'AudioPlaybackCard_title'}>{cardTitle}</Header>
			<b>
				<StyledContent data-testid={'AudioPlaybackCard_prompt'}>
					{renderCardContent ? <>{renderCardContent()}</> : null}
				</StyledContent>
			</b>
			<AudioWidget data-testid={'audioWidget'} srcUrl={audioSource} />
		</Card>
	);
};

const Card = styled(StyledCard)`
	padding: ${(props) => props.theme.spacing.lg};
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const Header = styled(P1)`
	font-weight: 600;
	margin-bottom: ${(props) => props.theme.spacing.xxs};
`;

const StyledContent = styled(P2)`
	font-weight: 500;
	margin-bottom: 12px;
`;
