import { useTranslation } from 'react-i18next';

import { ReportCardInfo } from 'components/report/AssessmentComponents/ReportCardInfo';
import { AudioWidget } from 'components/shared/AudioWidget';
import { LineDivider } from 'components/shared/LineDivider';
import { H4, P1 } from 'components/shared/designSystem';
import { ScoreWithInterpretation } from '../../../../mosaic/organisms';

import {
	AudioContainer,
	AudioWidgetWrapper,
	CaptionFaded,
	DelayedRecallLayoutContainer,
	DelayedRecallLayoutScores,
	P2Med,
	Recordings,
	ScoresWrapper,
} from './DelayedRecall.style';
import { DelayedResult } from './DelayedRecall.types';
import { renderRequiresReviewComponent } from '../../components/InterpretationComponent';

export type DelayedRecallLayoutProps = {
	delayedResult: DelayedResult;
	requiresReview: boolean;
};

export function DelayedRecallLayout({
	delayedResult,
	requiresReview,
}: Readonly<DelayedRecallLayoutProps>) {
	const { t } = useTranslation();

	const { correctResponses, trial } = delayedResult;

	return (
		<DelayedRecallLayoutContainer>
			<DelayedRecallLayoutScores>
				<ReportCardInfo
					title={t('web.report.pvltDelayedReport.delayedRecall')}
					tooltipText={t('web.report.pvltDelayedReport.subTitle')}
				/>
				<LineDivider />
				<ScoresWrapper>
					<div
						style={{
							minWidth: '445px',
							width: '445px',
						}}
					>
						<ScoreWithInterpretation
							score={{
								headerText: t(
									'web.report.uniqueResponses.correctResponses'
								).toUpperCase(),
								value: requiresReview
									? '--/6'
									: correctResponses,
							}}
							renderInterpretationText={() =>
								renderRequiresReviewComponent({
									requiresReview,
								})
							}
							// Tentatively hiding extra details but we should still show 'requires review' content
							hideTextPanel={!requiresReview}
						/>
					</div>
				</ScoresWrapper>
			</DelayedRecallLayoutScores>
			<Recordings>
				<H4
					style={{
						marginLeft: '24px',
						fontWeight: 500,
					}}
				>
					{t('web.shared.recordings')}
				</H4>
				<AudioContainer>
					<AudioWidgetWrapper>
						<div>
							<P1
								style={{
									fontWeight: 600,
								}}
							>
								{t(
									'web.report.pvltDelayedReport.delayedRecall'
								)}
							</P1>
							<P2Med
								style={{
									marginTop: '2px',
								}}
							>
								{t('web.shared.prompt')}
								{': '}
								{trial.prompt}
							</P2Med>
						</div>
						<AudioWidget srcUrl={trial.url} />
					</AudioWidgetWrapper>
				</AudioContainer>
			</Recordings>
			<CaptionFaded>{t('web.report.footer.info')}</CaptionFaded>
		</DelayedRecallLayoutContainer>
	);
}
