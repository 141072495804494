import { P2 } from 'components/shared/designSystem';
import { LoadingLayout } from 'components/shared/ReportLoader';
import { Trans } from 'react-i18next';
import { ordinalSuffixPerformance } from 'utils/ordinalSuffix';
import { RcrInterpretation } from './RcrInterpretation';

type RenderInterpretationComponentProps = {
	percentile?: number;
	quartile?: string;
	requiresReview?: boolean;
};

type RenderRequiresReviewComponentProps = {
	requiresReview: boolean;
};

export function renderInterpretationComponent({
	percentile,
	quartile,
	requiresReview,
}: Readonly<RenderInterpretationComponentProps>) {
	if (requiresReview) {
		return <RcrInterpretation />;
	}

	if (quartile) {
		return (
			<P2>
				<Trans
					i18nKey='web.report.uniqueResponses.performance_generic'
					components={{ b: <b /> }}
					values={{
						value: quartile,
					}}
				/>
			</P2>
		);
	}

	if (percentile) {
		return <P2>{ordinalSuffixPerformance(percentile)}</P2>;
	}

	return <></>;
}

export function renderRequiresReviewComponent({
	requiresReview,
}: Readonly<RenderRequiresReviewComponentProps>) {
	if (requiresReview) {
		return <RcrInterpretation />;
	} else {
		return <LoadingLayout title='' />;
	}
}
