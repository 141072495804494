export enum TRAILS_COLORS {
	Red = 'red',
	Yellow = 'yellow',
	Green = 'green',
	grey = 'grey',
}

export interface TrailsData {
	ageRange: string;
	duration: number;
	durationColor: TRAILS_COLORS;
	durationThreshold: {
		greenThreshold: string;
		redThreshold: string;
	};
	totalMistakes: number;
	totalMistakesColor: TRAILS_COLORS;
	thresholds: {
		green: string;
		yellow: string;
		red: string;
	};
	maxTimeReached: boolean;
	image: string;
	errorsQuartile: string;
	durationQuartile: string;
}
