import { ScoreTag } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';

import { Trials } from 'components/mosaic/molecules/Trials';
import { ScoreWithInterpretation } from 'components/mosaic/organisms';
import { ReportCardInfo } from 'components/report/AssessmentComponents/ReportCardInfo';
import { LineDivider } from 'components/shared/LineDivider';
import { H4 } from 'components/shared/designSystem';
import { FeatureFlags, useFeatureFlag } from 'features/feature-flags';
import { Recording, renderInterpretationComponent } from '../components';

import {
	Audio,
	BreakdownScores,
} from '../Fluency/Semantic/SemanticLayout.utils';

import {
	BreakdownTitle,
	CaptionFaded,
	Card,
	FluencyLayoutContainer,
	Header,
	RecordingsWrapper,
	ScoreBreakdownContainer,
	ScoresWrapper,
} from './FluencyLayout.style';

type FluencyLayoutProps = {
	audio: Audio;
	breakdownScores: BreakdownScores;
	correctResponses: string;
	percentile?: number;
	requiresReview?: boolean;
	title: string;
	tooltipText: string;
	englishSegment?: boolean;
};

export function FluencyLayout({
	audio,
	breakdownScores,
	correctResponses,
	percentile,
	requiresReview,
	title,
	tooltipText,
	englishSegment = true,
}: Readonly<FluencyLayoutProps>) {
	const { t } = useTranslation();

	const hideFluencyPercentile = useFeatureFlag(
		FeatureFlags.HideFluencyPercentile
	);

	const showCaptionForEnglishSegments = hideFluencyPercentile
		? englishSegment
		: true;

	const showInterpretation =
		requiresReview || (percentile !== undefined && percentile >= 0);

	return (
		<FluencyLayoutContainer data-testid='fluency-layout'>
			<Card>
				<Header>
					<ReportCardInfo title={title} tooltipText={tooltipText} />
					<LineDivider orientation='horizontal' />
					<ScoresWrapper data-testid='fluency-score-wrapper'>
						<div
							style={{
								width: '445px',
								minWidth: '445px',
							}}
						>
							{showInterpretation ? (
								<ScoreWithInterpretation
									score={{
										headerText: t(
											'web.report.uniqueResponses.correctResponses'
										).toUpperCase(),
										value: requiresReview
											? '--'
											: correctResponses,
									}}
									hideTextPanel={
										!showCaptionForEnglishSegments
									}
									renderInterpretationText={() =>
										renderInterpretationComponent({
											percentile: percentile ?? 0,
											requiresReview,
										})
									}
								/>
							) : (
								<ScoreTag
									title={t(
										'web.report.uniqueResponses.correctResponses'
									).toUpperCase()}
									score={correctResponses}
									roundedBorders
								/>
							)}
						</div>
						<ScoreBreakdown
							requiresReview={requiresReview}
							scores={breakdownScores}
						/>
					</ScoresWrapper>
				</Header>
			</Card>
			<RecordingsWrapper data-testid='recordings-wrapper'>
				<H4
					style={{
						marginLeft: '24px',
						fontWeight: 500,
					}}
				>
					{t('web.shared.recordings')}
				</H4>
				<Recording
					audioUrl={audio.url}
					prompt={audio.prompt}
					title={title}
				/>
			</RecordingsWrapper>
			<CaptionFaded>{t('web.report.footer.info')}</CaptionFaded>
		</FluencyLayoutContainer>
	);
}

type ScoreBreakdownProps = {
	requiresReview?: boolean;
	scores: {
		epoch1: string;
		epoch2: string;
		epoch3: string;
		epoch4: string;
	};
};

function ScoreBreakdown({
	requiresReview,
	scores,
}: Readonly<ScoreBreakdownProps>) {
	const { t } = useTranslation();

	return (
		<ScoreBreakdownContainer data-testid='score-breakdown'>
			<BreakdownTitle data-testid='score-breakdown-title'>
				{t('web.report.uniqueResponses.breakdown')}
			</BreakdownTitle>
			<Trials
				responseText={
					requiresReview
						? t('web.report.uniqueResponses.cannotCalculate')
						: t('web.report.uniqueResponses.correctResponses')
				}
				title={t('web.report.uniqueResponses.time', {
					value: '0-15',
				})}
				score={requiresReview ? '--' : scores.epoch1}
			/>
			<LineDivider />
			<Trials
				responseText={
					requiresReview
						? t('web.report.uniqueResponses.cannotCalculate')
						: t('web.report.uniqueResponses.correctResponses')
				}
				title={t('web.report.uniqueResponses.time', {
					value: '16-30',
				})}
				score={requiresReview ? '--' : scores.epoch2}
			/>
			<LineDivider />
			<Trials
				responseText={
					requiresReview
						? t('web.report.uniqueResponses.cannotCalculate')
						: t('web.report.uniqueResponses.correctResponses')
				}
				title={t('web.report.uniqueResponses.time', {
					value: '31-45',
				})}
				score={requiresReview ? '--' : scores.epoch3}
			/>
			<LineDivider />
			<Trials
				responseText={
					requiresReview
						? t('web.report.uniqueResponses.cannotCalculate')
						: t('web.report.uniqueResponses.correctResponses')
				}
				title={t('web.report.uniqueResponses.time', {
					value: '46-60',
				})}
				score={requiresReview ? '--' : scores.epoch4}
			/>
		</ScoreBreakdownContainer>
	);
}
