import styled, { css } from 'styled-components';

import { LineDivider } from 'components/shared/LineDivider';
import { LinusTooltip } from 'components/shared/LinusTooltip';
import { StyledCard } from 'components/shared/StyledCard';
import { H4, Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';

type ReportInfoPanelProps = {
	title: string;
	tooltipText?: string;
	children: React.ReactNode;
};

export function ReportInfoPanel({
	children,
	title,
	tooltipText,
}: Readonly<ReportInfoPanelProps>) {
	return (
		<Card>
			<Header>
				<ReportCardInfo title={title} tooltipText={tooltipText} />
				<LineDivider />
				{children}
			</Header>
		</Card>
	);
}

export function ReportCardInfo({
	title,
	tooltipText,
}: Readonly<Pick<ReportInfoPanelProps, 'title' | 'tooltipText'>>) {
	return (
		<HeaderContentContainer data-testid='report-card-info'>
			<Title>{title}</Title>
			{tooltipText && (
				<LinusTooltip
					overlay={<Tooltip>{tooltipText}</Tooltip>}
					tooltipIcon={
						<Icon
							icon={icons.InfoOutlined}
							iconWidth={24}
							iconHeight={24}
							viewBox='0 0 20 20'
						/>
					}
				/>
			)}
		</HeaderContentContainer>
	);
}

const Header = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

const Card = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.lg};
	`
);

const HeaderContentContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.xs};
	`
);

const Title = styled(H4)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

const Tooltip = styled.div(
	({ theme: { fontSize } }) => css`
		width: 500px;
		font-size: ${fontSize._16};
	`
);
