import styled, { css } from 'styled-components';

import { StyledCard } from 'components/shared/StyledCard';
import { Caption, P2 } from 'components/shared/designSystem';

export const DelayedRecallLayoutContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		margin-bottom: ${spacing.sm};
		gap: ${spacing.lg};
	`
);

export const DelayedRecallLayoutScores = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.lg};
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

export const ScoresWrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.md};
	`
);

export const P2Med = styled(P2)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const Recordings = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const AudioWidgetWrapper = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.lg};
		display: flex;
		flex-direction: column;
		gap: 12px;
	`
);

export const AudioContainer = styled.div`
	width: 50%;
	min-width: 500px;
`;

export const CaptionFaded = styled(Caption)(
	({ theme: { color, spacing } }) => css`
		color: ${color.bodyTextSecondary};
		margin-bottom: ${spacing.sm};
	`
);
