import styled from 'styled-components';

import { useRef, useState, useEffect } from 'react';

import { icons } from 'enums/icons';
import { Icon } from '../designSystem';
import { P2 } from '../designSystem/TextComponents';

import { ProgressBar } from './ProgressBar';
import { SkipButtons } from './SkipButtons';

type AudioWidgetProps = {
	srcUrl?: string;
};

export const AudioWidget = ({ srcUrl }: AudioWidgetProps) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);

	const audioPlayerRef = useRef<HTMLAudioElement>(null); // reference our audio component
	const progressBarRef = useRef<HTMLInputElement>(null); // reference our progress bar
	const animationRef = useRef(0); // reference the animation

	const calculateTime = (secs: number) => {
		const minutes = Math.floor(secs / 60);
		const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const seconds = Math.floor(secs % 60);
		const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
		return `${returnedMinutes}:${returnedSeconds}`;
	};

	const togglePlayPause = () => {
		const prevValue = isPlaying;
		setIsPlaying(!prevValue);
		if (!prevValue) {
			audioPlayerRef?.current?.play();
			animationRef &&
				(animationRef.current = requestAnimationFrame(whilePlaying));
		} else {
			audioPlayerRef?.current?.pause();
			cancelAnimationFrame(animationRef.current);
		}
	};

	const setProgressBarValue = (value?: number) => {
		if (progressBarRef?.current?.value) {
			progressBarRef.current.value = String(Math.ceil(value as number));
		}
	};

	const onChangeProgressBar = () => {
		if (audioPlayerRef?.current?.currentTime) {
			audioPlayerRef.current.currentTime = Number(
				progressBarRef?.current?.value
			);
		}
		changePlayerCurrentTime();
	};

	const whilePlaying = () => {
		setProgressBarValue(audioPlayerRef?.current?.currentTime);
		changePlayerCurrentTime();
		animationRef &&
			(animationRef.current = requestAnimationFrame(whilePlaying));

		// Resets play button state once the audio track is finished.
		if (Number(progressBarRef?.current?.value) === duration) {
			setIsPlaying(false);
		}
	};

	const setProgressBarSeekWidth = (progressCompletion: number) => {
		progressBarRef?.current?.style.setProperty(
			'--seek-before-width',
			`${progressCompletion}%`
		);
	};

	const changePlayerCurrentTime = () => {
		const progressBarValue = Number(progressBarRef?.current?.value);
		const progressCompletion = (progressBarValue / duration) * 100;

		setProgressBarSeekWidth(progressCompletion);
		setCurrentTime(progressBarValue);
	};

	const skip10sBack = () => {
		setProgressBarValue(Number(progressBarRef?.current?.value) - 10);
		onChangeProgressBar();
	};

	const skip10sAhead = () => {
		setProgressBarValue(Number(progressBarRef?.current?.value) + 10);
		onChangeProgressBar();
	};

	useEffect(() => {
		const audio = audioPlayerRef?.current;
		if (!audio) {
			return;
		}

		const listener = () => {
			if (audio.duration === Infinity) {
				// Workaround for webm files
				audio.currentTime = 10000000;
				setTimeout(() => {
					audio.currentTime = 0;
					setDuration(Math.ceil(audio.duration));
				}, 500);
			} else {
				setDuration(Math.ceil(audio.duration));
			}
		};

		audio.addEventListener('loadedmetadata', listener);
		return () => audio.removeEventListener('loadedmetadata', listener);
	}, []);

	return (
		<Container>
			<Audio
				ref={audioPlayerRef}
				src={srcUrl}
				preload='metadata'
				data-testid='audioWidget'
			/>
			<ProgressBarContainer>
				<PlayButton onClick={togglePlayPause}>
					{isPlaying ? (
						<Icon icon={icons.Pause} title='Pause' />
					) : (
						<Icon icon={icons.Play} title='Play' />
					)}
				</PlayButton>
				<ProgressBar
					progressBarRef={progressBarRef}
					max={duration}
					min={0}
					onChange={onChangeProgressBar}
				/>
			</ProgressBarContainer>
			<ControlsContainer>
				{/* current audio time */}
				<P2>{calculateTime(currentTime)}</P2>
				<SkipButtons
					onSkipBack={skip10sBack}
					onSkipFwd={skip10sAhead}
				/>
				{/* duration */}
				<P2>
					{duration && !isNaN(duration) && calculateTime(duration)}
				</P2>
			</ControlsContainer>
		</Container>
	);
};

const Audio = styled.audio``;

const Container = styled.div`
	width: 100%;
`;

const ProgressBarContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 12px;
`;

const PlayButton = styled.button`
	background: none;
	border: none;
	color: inherit;
	outline: inherit;
	padding: 0;
	cursor: pointer;
	font: inherit;
`;

const ControlsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-left: 36px;
`;
