import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { theme } from 'components/providers/StyleProvider/theme';
import { TRAILS_COLORS } from 'components/report/TrailsLayout/TrailsLayout.interface';
import { StyledLinkButton } from 'components/shared/StyledLinkButton';
import { hasIncompleteData } from 'utils/reportText';
import { ScoringDetailsModal } from '../ScoringDetailsModal';

import {
	AgeUnavailable,
	CompletionTimeContainer,
	Content,
	Description,
	DescriptionText,
	DurationValue,
	Info,
	LineDivider,
	Quartile,
	SolidDot,
	TimeLimit,
	Title,
	TotalDuration,
	Wrapper,
} from './TimeToComplete.styles';

export type TimeToCompleteProps = {
	readonly ageRange: string;
	readonly duration: number;
	readonly durationColor: TRAILS_COLORS;
	readonly durationQuartile: string;
	readonly durationThreshold: {
		greenThreshold: string;
		redThreshold: string;
	};
	readonly maxTimeReached?: boolean;
};

type CompletionTimeData = {
	color: string;
	ligherColor: string;
	i18nKey: string;
};

function getCompletionTimeData(
	durationColor: TRAILS_COLORS
): CompletionTimeData {
	if (durationColor === TRAILS_COLORS.Green) {
		return {
			color: theme.colors.green_light,
			ligherColor: 'rgba(225, 241, 214, 0.2)',
			i18nKey: 'web.report.trailsReport.timeToComplete.descriptionGreen',
		};
	}
	if (durationColor === TRAILS_COLORS.Yellow) {
		return {
			color: theme.colors.yellow_light,
			ligherColor: 'rgba(255, 242, 201, 0.2)',
			i18nKey: 'web.report.trailsReport.timeToComplete.descriptionYellow',
		};
	}

	if (durationColor === TRAILS_COLORS.Red) {
		return {
			color: theme.colors.orange_light,
			ligherColor: 'rgba(254, 213, 204, 0.2)',
			i18nKey: 'web.report.trailsReport.timeToComplete.descriptionRed',
		};
	}

	return {
		color: theme.colors.gray_60,
		ligherColor: theme.colors.gray_90,
		i18nKey: 'web.report.trailsReport.timeToComplete.descriptionGrey',
	};
}

export function TimeToComplete({
	ageRange,
	duration,
	durationColor,
	durationQuartile,
	durationThreshold,
	maxTimeReached = false,
}: TimeToCompleteProps) {
	const { t } = useTranslation();
	const [showScoringDetails, setShowScoringDetails] = useState(false);

	const scoringModalTitle = `${t(
		'web.report.trailsReport.timeToComplete.scoringDetails'
	)}: ${t('web.report.trailsReport.timeToComplete.title')}`;

	const heading = `${t(
		'web.report.trailsReport.timeToComplete.normativeRanges',
		{
			ageRange,
		}
	)}`;

	const greenText = `${t(
		'web.report.trailsReport.timeToComplete.greenRange',
		{
			greenThreshold: durationThreshold.greenThreshold,
		}
	)}`;

	const yellowText = `
	${t('web.report.trailsReport.timeToComplete.yellowRange', {
		greenThreshold: durationThreshold.greenThreshold,
		redThreshold: durationThreshold.redThreshold,
	})}`;

	const redText = `${t('web.report.trailsReport.timeToComplete.redRange', {
		redThreshold: durationThreshold.redThreshold,
	})}`;

	const completionTimeData: CompletionTimeData =
		getCompletionTimeData(durationColor);

	function handleViewScoring() {
		setShowScoringDetails(true);
	}

	function handleScoringDetailsClose() {
		setShowScoringDetails(false);
	}

	const hasCompleteInfo = !hasIncompleteData([
		durationThreshold.greenThreshold,
		durationThreshold.redThreshold,
	]);

	return (
		<Wrapper>
			<Title data-testid='time-to-complete-title'>
				{t('web.report.trailsReport.timeToComplete.title')}
			</Title>
			<LineDivider />
			<Info>
				<CompletionTimeContainer>
					<TotalDuration
						data-testid='total-duration'
						style={{
							backgroundColor: completionTimeData.color,
						}}
					>
						<DurationValue>
							{t(
								'web.report.trailsReport.timeToComplete.duration',
								{
									duration,
								}
							)}
						</DurationValue>
					</TotalDuration>
					<Content color={completionTimeData.ligherColor}>
						{durationColor !== TRAILS_COLORS.grey && (
							<SolidDot
								color={completionTimeData.color}
								size={12}
							/>
						)}
						<Description>
							<DescriptionText>
								<Trans
									i18nKey={completionTimeData.i18nKey}
									components={{
										b: (
											<span
												style={{
													fontWeight:
														theme.weight.medium,
												}}
											/>
										),
									}}
								/>
							</DescriptionText>
							{maxTimeReached && (
								<TimeLimit>
									{t(
										'web.report.trailsReport.timeToComplete.timeLimit'
									)}
								</TimeLimit>
							)}
							{durationQuartile !== 'NotAvailable' && (
								<Quartile>{durationQuartile}</Quartile>
							)}
						</Description>
					</Content>
				</CompletionTimeContainer>
				{hasCompleteInfo && (
					<StyledLinkButton
						role='button'
						onClick={handleViewScoring}
						style={{
							fontSize: 14,
							marginTop: 12,
						}}
					>
						<Trans i18nKey='web.shared.viewScoringDetails' />
					</StyledLinkButton>
				)}
				{hasCompleteInfo &&
					showScoringDetails &&
					durationThreshold.greenThreshold !== 'NotAvailable' && (
						<ScoringDetailsModal
							title={scoringModalTitle}
							heading={heading}
							greenText={greenText}
							yellowText={yellowText}
							redText={redText}
							onClose={handleScoringDetailsClose}
						/>
					)}
				{!hasCompleteInfo && (
					<AgeUnavailable>
						{t(
							'web.report.trailsReport.timeToComplete.ageUnavailable'
						)}
					</AgeUnavailable>
				)}
			</Info>
		</Wrapper>
	);
}
