import { BatteryType } from 'generated/graphql';
import { t } from 'i18next';

export interface GetReportTextData {
	batteryType: BatteryType;
	batteryName?: string;
}
/**
 *
 * @param param0 The data required
 * @returns Gets the translated report label depending on the type of report.
 */
export function getReportLabelBreadcrumb({
	batteryType,
	batteryName,
}: GetReportTextData) {
	switch (batteryType) {
		case BatteryType.Bds:
			return t('web.report.bdstReport.backwardsDigitSpan');
		case BatteryType.CognitiveEvaluation:
		case BatteryType.CcePlus:
		case BatteryType.Default:
			return t('web.shared.reports.coreCognitiveEvaluationReport');
		case BatteryType.Dcr:
		case BatteryType.DcrPlus:
			return t('web.report.dcrOnly.breadcrumbLabel');
		case BatteryType.Fast:
			return t('web.report.fastReport.fastTitle');
		case BatteryType.Gad7:
			return t('web.report.gad7.title');
		case BatteryType.Gds:
			return t('web.report.gds.title');
		case BatteryType.HearingScreener:
			return t('web.report.hearingScreener.title');
		case BatteryType.Iadl:
			return t('web.report.iadl.iadlAssessment');
		case BatteryType.Lhq:
			return t('web.report.patientReport.reportHeader.titleLHQ');
		case BatteryType.Orientation:
			return t('web.report.orientationQuestionnaireReport.title');
		case BatteryType.Trails:
			return t('web.report.trailsReport.title');
		case BatteryType.CommonSpirit:
			return t('web.report.commonSpirit.title');
		case BatteryType.Pvlt:
			return t('web.report.pvlt.title');
		case BatteryType.Phonemic:
			return t('web.report.phonemicFluencyReport.title');
		case BatteryType.Emory:
			return t('web.report.digitalConsultPathway.title');
		case BatteryType.CommonSpiritDementia:
			return t('web.report.commonSpirit.dementiaTitle');
		case BatteryType.Iuh:
			return t('web.report.iuh.title');
		case BatteryType.Adl:
			return t('web.report.adl.iadlAssessment');
		case BatteryType.IadlFast:
			return t('web.report.iadlFast.title');
		default:
			return (
				batteryName ??
				t('web.shared.reports.coreCognitiveEvaluationReport')
			);
	}
}
/**
 *
 * @param param0 The data required
 * @returns Gets the translated report label depending on the type of report.
 */
export function getReportLabelTitle({
	batteryType,
	batteryName,
}: GetReportTextData) {
	switch (batteryType) {
		case BatteryType.Bds:
			return t('web.report.bdstReport.backwardsDigitSpan');
		case BatteryType.CognitiveEvaluation:
		case BatteryType.CcePlus:
		case BatteryType.Default:
			return t(
				'web.report.cognitiveEvaluation.batteryTitleWithTrademark'
			);
		case BatteryType.Dcr:
		case BatteryType.DcrPlus:
			return t('web.report.dcrOnly.batteryTitleWithTrademark');
		case BatteryType.Fast:
			return t('web.report.fastReport.fastTitle');
		case BatteryType.Gad7:
			return t('web.report.gad7.title');
		case BatteryType.Gds:
			return t('web.report.gds.title');
		case BatteryType.HearingScreener:
			return t('web.report.hearingScreener.title');
		case BatteryType.Iadl:
			return t('web.report.iadl.iadlAssessment');
		case BatteryType.Lhq:
			return t('web.report.patientReport.reportHeader.titleLHQ');
		case BatteryType.Orientation:
			return t('web.report.orientationQuestionnaireReport.title');
		case BatteryType.Trails:
			return t('web.report.trailsReport.title');
		case BatteryType.CommonSpirit:
			return t('web.report.commonSpirit.title');
		case BatteryType.Pvlt:
			return t('web.report.pvlt.title');
		case BatteryType.Phonemic:
			return t('web.report.phonemicFluencyReport.title');
		case BatteryType.Emory:
			return t('web.report.digitalConsultPathway.title');
		case BatteryType.CommonSpiritDementia:
			return t('web.report.commonSpirit.dementiaTitle');
		case BatteryType.Iuh:
			return t('web.report.iuh.title');
		case BatteryType.Adl:
			return t('web.report.adl.iadlAssessment');
		case BatteryType.IadlFast:
			return t('web.report.iadlFast.title');
		default:
			return (
				batteryName ??
				t('web.report.cognitiveEvaluation.batteryTitleWithTrademark')
			);
	}
}

export function hasIncompleteData(content: string[]) {
	return !!content.find((element) => element.indexOf('NotAvailable') > -1);
}
