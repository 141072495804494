import {
	GetPatientsByOrgFilterField,
	GetPatientsByOrgFilterOperator,
	GetPatientsByOrgLogicalOperator,
	GetPatientsByOrgSortField,
	GetPatientsByOrgSortOrder,
} from '@lh/eng-platform-subject-service-rest-client';

import { SortProps } from 'components/shared/DataTable/PaginatedDataTable/pageHelpers';

import { GetPaginatedPatientsSortOptions } from './Patients.types';

export const getPaginatedPatientsFilterOptions = (
	searchValue?: string | null | undefined
) => {
	if (searchValue && searchValue?.length > 0) {
		return {
			filterField: [
				GetPatientsByOrgFilterField.FullName,
				GetPatientsByOrgFilterField.ExternalId,
			],
			filterOperator: [
				GetPatientsByOrgFilterOperator.Ilike,
				GetPatientsByOrgFilterOperator.Ilike,
			],
			filterValue: [searchValue, searchValue],
			logicalOperator: [GetPatientsByOrgLogicalOperator.Or],
		};
	}
	return {
		filterField: undefined,
		filterOperator: undefined,
		filterValue: undefined,
		logicalOperator: undefined,
	};
};

export const getPaginatedPatientsSortOptions = (
	sortProps: SortProps<GetPatientsByOrgSortField> | null | undefined
): GetPaginatedPatientsSortOptions => {
	if (!sortProps?.prop || !sortProps?.dir) {
		return {
			sortField: [GetPatientsByOrgSortField.CreatedAt],
			sortOrder: [GetPatientsByOrgSortOrder.Desc],
		};
	}
	const sortDir = [];
	if (sortProps?.dir === 'ASC') {
		sortDir.push(GetPatientsByOrgSortOrder.Asc);
	} else if (sortProps?.dir === 'DESC') {
		sortDir.push(GetPatientsByOrgSortOrder.Desc);
	}
	switch (sortProps?.prop) {
		case GetPatientsByOrgSortField.FullName:
			return {
				sortField: [GetPatientsByOrgSortField['FullName']],
				...(sortDir && { sortOrder: sortDir }),
			};
		case GetPatientsByOrgSortField.BirthDate:
			return {
				sortField: [GetPatientsByOrgSortField['BirthDate']],
				...(sortDir && { sortOrder: sortDir }),
			};
		default:
			return {
				sortField: undefined,
				sortOrder: undefined,
			};
	}
};
