import styled, { css } from 'styled-components';

import { Caption, H4, P2 } from 'components/shared/designSystem';
import { StyledCard } from '../../../../shared/StyledCard';

export const Wrapper = styled.div(
	({ theme: { spacing } }) => css`
		margin-bottom: ${spacing.sm};
	`
);

export const ToolTip = styled.div`
	text-align: left;
	width: 284px;
`;

export const TooltipText = styled(P2)(
	({ theme: { color, weight } }) => css`
		color: ${color.white};
		font-weight: ${weight.light};
	`
);

export const InfoIcon = styled.div`
	position: relative;
	margin-left: 11px;
`;

export const Card = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.lg};
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

export const Header = styled(H4)`
	font-weight: 600;
`;

export const Container = styled.div(
	({ theme: { spacing, breakpoints } }) => css`
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: ${spacing.xl};
		justify-content: space-between;
		margin-bottom: ${spacing.lg};
		${breakpoints.tabletDown} {
			flex-direction: column;
			width: 40%;
		}
		${breakpoints.mobile} {
			width: 30%;
		}
	`
);

export const Row = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.md};
	`
);

export const TrialsContainer = styled.div(
	({ theme: { spacing } }) =>
		css`
			display: flex;
			flex-direction: column;
			gap: ${spacing.sm};
			width: 100%;
		`
);

export const CaptionFaded = styled(Caption)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
	`
);
