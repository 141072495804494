import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';
import { useImmediateRecallData } from './ImmediateRecall.utils';
import { ImmediateRecallLayout } from './ImmediateRecallLayout';

type ImmediateRecallReportProps = {
	segmentResult: DeepSegmentResult;
};

export const ImmediateRecallReport = ({
	segmentResult,
}: Readonly<ImmediateRecallReportProps>) => {
	const { trialsResult, requiresReview } =
		useImmediateRecallData(segmentResult);

	return (
		<ImmediateRecallLayout
			trials={trialsResult}
			requiresReview={requiresReview}
		/>
	);
};
