import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';
import { DelayedRecallLayout } from './DelayedRecallLayout';
import { useDelayedRecallData } from './DelayedRecallReport.utils';

type DelayedRecallReportProps = {
	segmentResult: DeepSegmentResult;
};

export const DelayedRecallReport = ({
	segmentResult,
}: Readonly<DelayedRecallReportProps>) => {
	const { delayedResult, requiresReview } =
		useDelayedRecallData(segmentResult);
	return (
		<DelayedRecallLayout
			delayedResult={delayedResult}
			requiresReview={requiresReview}
		/>
	);
};
