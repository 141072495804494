import {
	DeepSegmentResult,
	MetricItem,
} from '@lh/eng-platform-battery-service-rest-client';

import { useEffect, useMemo, useState } from 'react';

import { S3Segment, useFetchS3Segments } from 'utils/fetchS3Segments';
import { reverseString } from 'utils/stringUtils';

import { BreakdownScores } from './BdstReport.types';

const MAX_MATCH_SCORE = 5;
const MAX_ANY_SCORE = 5;
const MAX_TOTAL_SCORE = 15;

export function useBdstData(segmentResult: DeepSegmentResult) {
	const { breakdownScores, correctResponses, quartile } = useMemo(() => {
		return parseBdstMetrics(segmentResult.metricItems);
	}, [segmentResult.metricItems.length]);

	const filesToFetch = useMemo(() => {
		return buildFilesToFetch(segmentResult.id);
	}, [segmentResult.id]);

	const [trialRecording, setTrialRecording] = useState({
		trialOne: '',
		trialTwo: '',
		trialThree: '',
	});

	const audioFiles = useFetchS3Segments({
		segments: filesToFetch,
		blobType: 'audio/mp4',
	});

	useEffect(() => {
		audioFiles.forEach((file) => {
			if (!file.responseUrl) return;
			if (file.type === BdstFileNames.Trial1) {
				setTrialRecording({
					...trialRecording,
					trialOne: file.responseUrl,
				});
			}
			if (file.type === BdstFileNames.Trial2) {
				setTrialRecording({
					...trialRecording,
					trialTwo: file.responseUrl,
				});
			}
			if (file.type === BdstFileNames.Trial3) {
				setTrialRecording({
					...trialRecording,
					trialThree: file.responseUrl,
				});
			}
		});
		// eslint disable next line react-hooks/exhaustive-deps
	}, [audioFiles]);

	return {
		breakdownScores,
		correctResponses,
		quartile,
		recordings: {
			trialOne: trialRecording.trialOne,
			trialTwo: trialRecording.trialTwo,
			trialThree: trialRecording.trialThree,
		},
	};
}

export function parseBdstMetrics(metricItems: MetricItem[]) {
	const breakdownScores: BreakdownScores = {
		trialOne: {
			audio: {},
			score: {},
		},
		trialTwo: {
			audio: {},
			score: {},
		},
		trialThree: {
			audio: {},
			score: {},
		},
	};
	let correctResponses: string | undefined;
	let quartile: string | undefined;

	metricItems.forEach((metricItem) => {
		if (metricItem.key.includes('trial_1')) {
			if (metricItem.key.includes('expected_digits')) {
				breakdownScores.trialOne.audio.prompt = reverseString(
					metricItem.value
				);
			}

			if (metricItem.key.includes('transcribed_digits')) {
				breakdownScores.trialOne.audio.backwards = metricItem.value;
			}

			if (metricItem.key.includes('match_score')) {
				breakdownScores.trialOne.score.any = `${metricItem.value}/${MAX_MATCH_SCORE}`;
			}

			if (metricItem.key.includes('order_score')) {
				breakdownScores.trialOne.score.serial = `${metricItem.value}/${MAX_ANY_SCORE}`;
			}
		}

		if (metricItem.key.includes('trial_2')) {
			if (metricItem.key.includes('expected_digits')) {
				breakdownScores.trialTwo.audio.prompt = reverseString(
					metricItem.value
				);
			}

			if (metricItem.key.includes('transcribed_digits')) {
				breakdownScores.trialTwo.audio.backwards = metricItem.value;
			}

			if (metricItem.key.includes('match_score')) {
				breakdownScores.trialTwo.score.any = `${metricItem.value}/${MAX_MATCH_SCORE}`;
			}

			if (metricItem.key.includes('order_score')) {
				breakdownScores.trialTwo.score.serial = `${metricItem.value}/${MAX_ANY_SCORE}`;
			}
		}

		if (metricItem.key.includes('trial_3')) {
			if (metricItem.key.includes('expected_digits')) {
				breakdownScores.trialThree.audio.prompt = reverseString(
					metricItem.value
				);
			}

			if (metricItem.key.includes('transcribed_digits')) {
				breakdownScores.trialThree.audio.backwards = metricItem.value;
			}

			if (metricItem.key.includes('match_score')) {
				breakdownScores.trialThree.score.any = `${metricItem.value}/${MAX_MATCH_SCORE}`;
			}

			if (metricItem.key.includes('order_score')) {
				breakdownScores.trialThree.score.serial = `${metricItem.value}/${MAX_ANY_SCORE}`;
			}
		}

		if (metricItem.key === 'backward_digit_span_total_ser_order_score') {
			correctResponses = isNaN(Number(metricItem.value))
				? undefined
				: `${metricItem.value}/${MAX_TOTAL_SCORE}`;
		}

		if (
			metricItem.key ===
			'backward_digit_span_total_ser_order_score_quartile'
		) {
			quartile =
				metricItem.value === 'NotAvailable'
					? undefined
					: metricItem.value;
		}
	});

	return {
		breakdownScores,
		correctResponses,
		quartile,
	};
}

export const buildFilesToFetch = (segmentResultId: string): S3Segment[] => {
	return [
		{
			segmentResultId,
			type: BdstFileNames.Trial1,
			filename: 'backwards_digit_span_audio_trial1.mp4',
		},
		{
			segmentResultId,
			type: BdstFileNames.Trial1,
			filename: 'backwards_digit_span_audio_trial1.webm',
		},
		{
			segmentResultId,
			type: BdstFileNames.Trial2,
			filename: 'backwards_digit_span_audio_trial2.mp4',
		},
		{
			segmentResultId,
			type: BdstFileNames.Trial2,
			filename: 'backwards_digit_span_audio_trial2.webm',
		},
		{
			segmentResultId,
			type: BdstFileNames.Trial3,
			filename: 'backwards_digit_span_audio_trial3.mp4',
		},
		{
			segmentResultId,
			type: BdstFileNames.Trial3,
			filename: 'backwards_digit_span_audio_trial3.webm',
		},
	];
};

export enum BdstFileNames {
	Trial1 = 'trial1',
	Trial2 = 'trial2',
	Trial3 = 'trial3',
}
