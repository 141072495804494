import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';
import {
	GetPatientsByOrgSortField,
	PaginatedPatients,
	Patient,
} from '@lh/eng-platform-subject-service-rest-client';

import * as Sentry from '@sentry/browser';
import { useQueries, useQueryClient } from '@tanstack/react-query';

import { sendDebouncedEventData, sendEventData } from 'analytics';
import { useDeleteAssignmentMutation } from 'api/assignment';
import { getPatient } from 'api/patient';
import { QueryKey } from 'api/query';
import { BatteryConfirmation } from 'components/modals/BatteryConfirmation';
import { icons } from 'enums/icons';
import { FeatureFlags, useFeatureFlag } from 'features/feature-flags';
import { AnimatePresence } from 'framer-motion';
import { AnalyticsAction, AssignmentStatus, SortDir } from 'generated/graphql';
import { ERROR } from 'logging/linusLogger';
import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useModalStore } from 'store';
import { useTheme } from 'styled-components';
import { UserContext } from '../../context/UserContext';
import { LinusPaginatedDataTable } from '../../shared/DataTable/PaginatedDataTable';
import {
	DEFAULT_PAGE_SIZE,
	SortProps,
} from '../../shared/DataTable/PaginatedDataTable/pageHelpers';
import {
	AddBatteryProps,
	TableData,
	columns,
	mapData,
} from '../../shared/DataTable/schemas/patientsSchema';
import { Header } from '../../shared/Header';
import { LinusModal } from '../../shared/LinusModal';
import { LinusModalDialog } from '../../shared/LinusModalDialog';
import { useActiveTableHeader } from '../../shared/hooks/useActiveTableHeader';
import { useSessionIssue } from '../../shared/hooks/useSessionIssue';

import { getAssignedAssignment } from 'api/assignment/useAssignedAssignment';
import { getCompletedBatteryResult } from 'api/battery/useCompletedBatteryResults';
import { AddBatteryForNewPatientHeader } from '../AddBatteryForNewPatientHeader';
import { AddBatteryForPatient, CustomPatient } from '../AddBatteryForPatient';
import { AddBatteryForPatientHeader } from '../AddBatteryForPatientHeader';
import { AddPatientForm } from '../AddPatientForm';
import { ArchivePatientConfirmation } from '../ArchivePatient/ArchivePatientConfirmation';
import { RemoveBatteryForPatient } from '../RemoveBatteryForPatient';
import { usePatientsTableData } from './usePatientsTableData';

const ROWS_PER_PAGE = 10;

interface PatientsTestingProps {
	isTest?: boolean;
}

const MOCK_PATIENT_ID = '878a99e2-fadf-47c1-8abc-74be30b5ae1c';

export const Patients = ({
	isTest = false,
}: PatientsTestingProps): JSX.Element | null => {
	const { activeHeader, setActiveHeader } = useActiveTableHeader();
	const { currentUser } = useContext(UserContext);
	const archivePatientFlag = useFeatureFlag(FeatureFlags.ArchivePatient);
	const queryClient = useQueryClient();
	const sessionIssuesEnabled = useSessionIssue();
	const theme = useTheme();
	const { t } = useTranslation();

	const {
		mutateAsync: deleteAssignmentForParticipant,
		isError: deleteAssignmentError,
	} = useDeleteAssignmentMutation();

	const tableRef = useRef<{ clearSearchInput(): void }>(null);

	const [extendedPatientInfo, setExtendedPatientInfo] =
		useState<CustomPatient>();
	const [isNewPatientModalOpen, setIsNewPatientModalOpen] = useState(false);
	const [isAssignBatteryModalOpen, setIsAssignBatteryModalOpen] =
		useState(false);
	const [isRemoveBatteryModalOpen, setIsRemoveBatteryModalOpen] =
		useState(false);
	const [isBatteryRemovedModalOpen, setIsBatteryRemovedModalOpen] =
		useState(false);
	const [isArchiveConfirmationModalOpen, setIsArchiveConfirmationModalOpen] =
		useState(false);
	const [
		isArchivedSuccessfullyModalOpen,
		setIsArchivedSuccessfullyModalOpen,
	] = useState(false);
	const [isCannotArchiveModalOpen, setIsCannotArchiveModalOpen] =
		useState(false);
	const [page, setPage] = useState(1);
	const [patientInfo, setPatientInfo] = useState<AddBatteryProps>();
	const [search, setSearch] = useState<string>('');
	const [sort, setSort] =
		useState<SortProps<GetPatientsByOrgSortField> | null>(null);

	const dateFormat = currentUser.organizationDateFormat || 'MM/dd/yyyy';
	const defaultTimezone = currentUser.organizationDefaultTimezone;

	const visible = useModalStore(
		(state) => state.visible.BatteryAssignmentConfirmation
	);
	const close = useModalStore((state) => state.close);

	const {
		data: patients,
		loading: loadingPatients,
		totalCount,
		reloadPatients,
	} = usePatientsTableData({
		organizationId: currentUser?.organizationId,
		page: page - 1,
		pageSize: ROWS_PER_PAGE,
		searchValue: search ?? null,
		sort,
	});

	// Get assignment for each patient
	const { data: assignments, pending: pendingAssignments } = useQueries({
		queries: patients?.results
			? patients?.results.map((patient: Patient) => {
					const patientId = patient?.id;
					return {
						queryKey: [QueryKey.Assignment, patientId],
						queryFn: () => getAssignedAssignment(patientId),
						staleTime: 60 * 1000, // 1 minute,
					};
			  })
			: [],
		combine: (results) => {
			return {
				data: results.map((result) => result.data),
				pending: results.map((result) => result.isPending),
				error: results.map((result) => result.error),
			};
		},
	});

	// Get latest battery result for each patient
	const { data: batteryResults, pending: pendingBatteryResults } = useQueries(
		{
			queries: patients?.results
				? patients?.results.map((patient: Patient) => {
						const patientId = patient?.id;
						return {
							queryKey: [QueryKey.BatteryResults, patientId],
							queryFn: () => getCompletedBatteryResult(patientId),
							staleTime: Infinity,
						};
				  })
				: [],
			combine: (results) => {
				return {
					data: results.map((result) => result.data),
					pending: results.map((result) => result.isPending),
					error: results.map((result) => result.error),
				};
			},
		}
	);

	/**
	 * Logs
	 */
	useEffect(() => {
		sendEventData({ eventType: AnalyticsAction.ViewedPatients });
	}, []);

	useEffect(() => {
		setPage(1);
		setSearch('');
		setSort(null);
	}, [currentUser.organizationId]);

	useEffect(() => {
		if (search && !loadingPatients) {
			sendDebouncedEventData({
				eventType: AnalyticsAction.SearchingTable,
				eventProperties: {
					hasResults: !!patients?.results.length,
				},
			});
		}
	}, [search, patients?.results.length, loadingPatients]);

	const handleSearchChange = (predicate: string) => {
		if (predicate !== search) {
			setPage(1);
		}
		setSearch(predicate);
	};

	/**
	 * Reset data when organization changes
	 */
	useEffect(() => {
		resetData();
	}, [currentUser.organizationId]);

	useEffect(() => {
		async function getExtendedPatientData(patientId: string) {
			try {
				const patientData = await queryClient.fetchQuery({
					queryKey: [
						QueryKey.Patient,
						patientId,
						currentUser.organizationId,
					],
					queryFn: () =>
						getPatient(patientId, currentUser.organizationId),
					staleTime: Infinity,
				});
				// The above query is returning a different response based on new patient or not.
				// Hence why we might need to account for different responses below (axios reponse containing patient or just patient)

				if (patientData) {
					setExtendedPatientInfo({
						newPatient: patientInfo?.newPatient ?? false,
						...patientData,
					});
				}
			} catch (error: any) {
				ERROR(error);
			}
		}

		if (patientInfo?.id) {
			getExtendedPatientData(patientInfo.id);
		}

		if (isTest) {
			getExtendedPatientData(MOCK_PATIENT_ID);
		}
	}, [patientInfo, currentUser.organizationId]);

	const handleCreatePatient = useCallback(() => {
		sendEventData({ eventType: AnalyticsAction.AddedNewPatient });
		setIsNewPatientModalOpen(false);
		setIsAssignBatteryModalOpen(true);
		setActiveHeader(undefined);
		reloadPatients();
		queryClient.setQueryData<PaginatedPatients>(
			[QueryKey.Patients, currentUser.organizationId],
			(data) => {
				if (!data) {
					return;
				}
				return {
					...data,
					totalCount: +data.totalCount + 1,
				};
			}
		);
	}, [currentUser.organizationId, reloadPatients, setActiveHeader]);

	const handleBatteryClick = useCallback((props: AddBatteryProps) => {
		setPatientInfo(props);
		setIsAssignBatteryModalOpen(true);
	}, []);

	const handleCancel = useCallback(() => {
		closeModals();
		setPatientInfo(undefined);
		setExtendedPatientInfo(undefined);
		reloadPatients();
	}, [search]);

	const handleSuccess = useCallback(() => {
		closeModals();
		setIsArchivedSuccessfullyModalOpen(true);
		reloadPatients();
		queryClient.setQueryData<PaginatedPatients>(
			[QueryKey.Patients, currentUser.organizationId],
			(data) => {
				if (!data) {
					return;
				}
				return {
					...data,
					totalCount: +data.totalCount - 1,
				};
			}
		);
	}, [currentUser.organizationId, reloadPatients]);

	const handleFail = () => {
		closeModals();
		setIsCannotArchiveModalOpen(true);
	};

	const closeModals = () => {
		setIsNewPatientModalOpen(false);
		setIsAssignBatteryModalOpen(false);
		setIsRemoveBatteryModalOpen(false);
		setIsBatteryRemovedModalOpen(false);
		setIsArchiveConfirmationModalOpen(false);
		setIsArchivedSuccessfullyModalOpen(false);
		setIsCannotArchiveModalOpen(false);
	};

	const resetData = (): void => {
		setPage(1);
		setSort(null);
		setSearch('');
		setActiveHeader(undefined);
		tableRef.current?.clearSearchInput();
	};

	const onSort = (dir: SortDir | undefined, prop: string) => {
		if (!dir) {
			setSort(null);
		} else {
			setActiveHeader(prop);
			setSort({
				dir,
				prop: prop as GetPatientsByOrgSortField,
			});
		}
	};

	const handleRemoveBattery = useCallback(
		async (row: TableData) => {
			if (sessionIssuesEnabled) {
				setPatientInfo(row);
				setIsRemoveBatteryModalOpen(true);
			} else {
				const id = row.currentAssignmentId;
				const participantId = row.id;
				// The ID should always be there, this conditional was to make TS happy
				if (!id) return;
				try {
					await deleteAssignmentForParticipant(id);
					if (!deleteAssignmentError) {
						// Invalidate assignment query since the previously cached data is stale
						await queryClient.invalidateQueries({
							queryKey: [QueryKey.Assignment, participantId],
						});
						sendEventData({
							eventType: AnalyticsAction.RemovedBattery,
						});
						setPatientInfo({
							id: row.id,
							firstName: row.firstName,
							lastName: row.lastName,
							newPatient: false,
							organizationId: row.organizationId,
						});
						setIsBatteryRemovedModalOpen(true);
					}
					reloadPatients();
				} catch (err) {
					ERROR(
						`Error trying to delete assignment from Patients id: ${id}: `,
						err
					);
					Sentry.captureException(err);
				}
			}
		},
		[
			reloadPatients,
			deleteAssignmentForParticipant,
			setIsBatteryRemovedModalOpen,
			currentUser.organizationId,
			sessionIssuesEnabled,
		]
	);

	const tableColumns = columns(
		dateFormat,
		defaultTimezone,
		[
			{
				key: 'archiveParticipant',
				value: t`web.archive`,
				callback: (row: TableData) => {
					sendEventData({
						eventType: AnalyticsAction.ArchivedPatient,
						eventProperties: {
							source: 'Kebab menu',
						},
					});
					setPatientInfo(row);
					setIsArchiveConfirmationModalOpen(true);
				},
				requirement: () => archivePatientFlag,
				operations: [OperationToken.ArchivePatients],
			},
			{
				key: 'removeBattery',
				value: t`web.shared.removeBatteryModal.removeBattery`,
				callback: (row: TableData) => handleRemoveBattery(row),
				requirement: ({ status }) =>
					!!status &&
					status !== AssignmentStatus.Deleted &&
					status !== AssignmentStatus.Complete,
				operations: [OperationToken.DeleteSchedule],
			},
		],
		handleBatteryClick
	);

	const tableData = useMemo(() => {
		return mapData(
			patients?.results,
			assignments,
			pendingAssignments,
			batteryResults,
			pendingBatteryResults
		);
	}, [
		currentUser?.organizationId,
		patients?.results,
		assignments,
		batteryResults,
	]);

	const noDataIcon = icons.NoDataUser;
	const count = patients?.results.length ?? 0;
	const total = totalCount ?? 0;

	return (
		<>
			<Header />
			<LinusPaginatedDataTable
				_ref={tableRef}
				title={t`web.stringStrategies.clinical.participants`}
				buttonText={t`web.patients.patientModal.titleAdd`}
				columns={tableColumns}
				tableData={tableData}
				operations={[OperationToken.CreateParticipant]}
				rowsPerPage={DEFAULT_PAGE_SIZE}
				noDataIcon={noDataIcon}
				searchBarPlaceholder={t`web.shared.search.byNameOrId`}
				onHeaderButtonClick={() => {
					setIsNewPatientModalOpen(true);
				}}
				buttonIcon={icons.AddUserOutlined}
				count={count}
				total={total}
				currentPage={page}
				setCurrentPage={(pageNumber: number) => setPage(pageNumber)}
				loading={loadingPatients}
				hasInitialData={false}
				notFoundTitle={t('web.shared.search.noMatchFound', {
					entity: t(
						'web.stringStrategies.clinical.participants'
					).toLowerCase(),
				})}
				notFoundSubtitle={t(
					'web.shared.search.addNewOrChangeSpelling',
					{
						entity: t(
							'web.stringStrategies.clinical.participant'
						).toLowerCase(),
					}
				)}
				onSort={onSort}
				onFilter={handleSearchChange}
				activeHeader={activeHeader}
			/>
			<AnimatePresence>
				{isNewPatientModalOpen && (
					<LinusModal
						title={t`web.patients.patientModal.titleAdd`}
						titleIcon={icons.AddUserSolid}
						titleIconColor={theme.color.iconAddUserSolid}
						onClose={handleCancel}
					>
						<AddPatientForm
							onCancel={handleCancel}
							onFinish={handleCreatePatient}
							setPatient={setPatientInfo}
						/>
					</LinusModal>
				)}
				{isAssignBatteryModalOpen && extendedPatientInfo && (
					<LinusModal
						onClose={handleCancel}
						dataId='patient_added_modal'
						width='770px'
						style={{ overflowY: 'unset' }}
					>
						<AddBatteryForPatient
							patient={extendedPatientInfo}
							onCancel={handleCancel}
							renderProps={() =>
								patientInfo?.newPatient ? (
									<AddBatteryForNewPatientHeader
										patient={patientInfo}
									/>
								) : (
									<AddBatteryForPatientHeader
										patient={patientInfo}
									/>
								)
							}
						/>
					</LinusModal>
				)}
				{visible && (
					<BatteryConfirmation
						onClose={() => close('BatteryAssignmentConfirmation')}
					/>
				)}
				{isRemoveBatteryModalOpen && patientInfo?.id && (
					<LinusModal
						onClose={handleCancel}
						dataId='remove_battery_modal'
					>
						<RemoveBatteryForPatient
							patient={patientInfo}
							onCancel={handleCancel}
						/>
					</LinusModal>
				)}
				{isBatteryRemovedModalOpen && (
					<LinusModalDialog
						onClose={handleCancel}
						title={t`web.shared.removeBatteryModal.title`}
						titleIcon={icons.CheckmarkSolid}
						titleIconColor={theme.color.alertSuccess}
						acceptButtonText={t`web.shared.close`}
						acceptButtonCallback={handleCancel}
					>
						<Trans
							i18nKey='web.shared.removeBatteryModal.batteryRemoved'
							values={{
								firstName: patientInfo?.firstName,
								lastName: patientInfo?.lastName,
							}}
							components={{
								b: <strong />,
							}}
						/>
					</LinusModalDialog>
				)}
				{isArchiveConfirmationModalOpen && archivePatientFlag && (
					<LinusModal
						title={t`web.archivePatientModal.title`}
						titleIcon={icons.RemoveUserSolid}
						titleIconColor={theme.color.alertError}
						onClose={handleCancel}
					>
						<ArchivePatientConfirmation
							type='Kebab'
							patientId={patientInfo?.id || ''}
							onCancel={handleCancel}
							onSuccess={handleSuccess}
							onFail={handleFail}
							firstName={patientInfo?.firstName || ''}
							lastName={patientInfo?.lastName || ''}
							organizationName={currentUser.organizationName}
						/>
					</LinusModal>
				)}
				{isArchivedSuccessfullyModalOpen && archivePatientFlag && (
					<LinusModalDialog
						width='485px'
						title={t`web.archivePatientModal.archived`}
						titleIcon={icons.CheckmarkSolid}
						titleIconColor={theme.color.alertSuccess}
						acceptButtonText={t`web.archivePatientModal.close`}
						acceptButtonCallback={handleCancel}
						onClose={handleCancel}
					>
						<Trans
							i18nKey='web.archivePatientModal.success'
							values={{
								firstName: patientInfo?.firstName,
								lastName: patientInfo?.lastName,
							}}
							components={{
								b: <strong />,
							}}
						/>
					</LinusModalDialog>
				)}
				{isCannotArchiveModalOpen && archivePatientFlag && (
					<LinusModalDialog
						width='485px'
						onClose={handleCancel}
						title={t`web.archivePatientModal.cannotArchive`}
						titleIcon={icons.AlertShieldSolid}
						titleIconColor={theme.color.alertError}
						acceptButtonText={t`web.archivePatientModal.close`}
						acceptButtonCallback={handleCancel}
					>
						<Trans i18nKey='web.archivePatientModal.takingAssessment' />
					</LinusModalDialog>
				)}
			</AnimatePresence>
		</>
	);
};
