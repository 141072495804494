import styled, { css } from 'styled-components';

import { Caption, H4, P2 } from 'components/shared/designSystem';
import { StyledCard } from '../../../../shared/StyledCard';

export const Wrapper = styled.div(
	({ theme: { spacing } }) => css`
		margin-bottom: ${spacing.sm};
	`
);

export const ToolTip = styled.div`
	text-align: left;
	max-width: 284px;
`;

export const TooltipText = styled(P2)(
	({ theme: { color, weight } }) => css`
		color: ${color.white};
		font-weight: ${weight.light};
	`
);

export const InfoIcon = styled.div`
	position: relative;
	margin-left: 11px;
`;

export const Card = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.lg};
		width: 100%;
	`
);

export const LineDivider = styled.div(
	({ theme: { spacing, color } }) => css`
		height: 1px;
		margin-top: ${spacing.md};
		margin-bottom: ${spacing.md};
		background-color: ${color.infoSeparator};
	`
);

export const Header = styled(H4)(
	({ theme: { spacing, weight } }) => css`
		font-weight: ${weight.medium};
		margin-left: ${spacing.lg};
		margin-bottom: 12px;
	`
);

export const Container = styled.div(
	({ theme: { spacing, breakpoints } }) => css`
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: ${spacing.xl};
		justify-content: space-between;
		margin-bottom: ${spacing.lg};

		${breakpoints.tabletDown} {
			flex-direction: column;
			width: 40%;
		}
		${breakpoints.mobile} {
			width: 30%;
		}
	`
);

export const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

export const TrialsDivider = styled.div(
	({ theme: { spacing, color } }) => css`
		height: 1px;
		margin-top: ${spacing.sm};
		margin-bottom: ${spacing.sm};
		background-color: ${color.infoSeparator};
	`
);

export const TrialsContainer = styled.div(
	({ theme: { spacing } }) =>
		css`
			margin-left: ${spacing.md};
			gap: ${spacing.sm};
			width: 100%;
		`
);

export const CaptionFaded = styled(Caption)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
	`
);
