import { Trans, useTranslation } from 'react-i18next';

import { theme } from 'components/providers/StyleProvider/theme';
import { P2 } from 'components/shared/designSystem';

export function RcrInterpretation() {
	const { t } = useTranslation();
	return (
		<P2 style={{ fontWeight: theme.weight.medium }}>
			<Trans
				i18nKey='web.report.uniqueResponses.rcr'
				components={{
					b: <strong />,
				}}
			/>{' '}
			{t('web.report.uniqueResponses.rcrInfo')}
		</P2>
	);
}
