import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { theme } from 'components/providers/StyleProvider/theme';
import { AudioWidget } from 'components/shared/AudioWidget';
import { LoadingDots } from 'components/shared/LoadingDots';
import { StyledCard } from 'components/shared/StyledCard';
import { P1, P2 } from 'components/shared/designSystem';

type RecordingProps = {
	audioUrl: string;
	prompt: string;
	title: string;
};

export function Recording({
	audioUrl,
	prompt,
	title,
}: Readonly<RecordingProps>) {
	const { t } = useTranslation();

	return (
		<AudioContainer>
			<AudioWidgetWrapper>
				<div>
					<P1
						style={{
							fontWeight: 600,
						}}
					>
						{title}
					</P1>
					<P2
						style={{
							marginTop: '2px',
							fontWeight: theme.weight.medium,
						}}
					>
						{t('web.shared.prompt')}
						{': '}
						{prompt}
					</P2>
				</div>
				{audioUrl.length > 0 ? (
					<AudioWidget srcUrl={audioUrl} />
				) : (
					<LoadingDots />
				)}
			</AudioWidgetWrapper>
		</AudioContainer>
	);
}

const AudioWidgetWrapper = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.lg};
		display: flex;
		flex-direction: column;
		gap: 12px;
	`
);

const AudioContainer = styled.div`
	width: 50%;
	min-width: 500px;
`;
