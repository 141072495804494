import { useQuery } from '@tanstack/react-query';

import {
	AssignmentStatus,
	GetAssignmentsFilterField,
	GetAssignmentsFilterOperator,
	GetAssignmentsSortField,
	GetAssignmentsSortOrder,
} from '@lh/eng-platform-battery-service-rest-client';

import { getBatteryService } from '../getBatteryService';
import { QueryKey } from '../query';

export async function getAssignedAssignment(
	patientId: string | null,
	signal?: AbortSignal
) {
	if (!patientId) {
		throw new Error("useAssignedAssignmentsQuery: patientId can't be null");
	}

	const service = await getBatteryService();
	const { data } = await service.getAssignments(
		{
			participantId: patientId,
			filterField: [GetAssignmentsFilterField.AssignmentStatus],
			filterOperator: [GetAssignmentsFilterOperator.In],
			filterValue: [
				[
					AssignmentStatus.Assigned,
					AssignmentStatus.Started,
					AssignmentStatus.Deleted,
				].join(','),
			],
			sortField: [GetAssignmentsSortField.AssignedTime],
			sortOrder: [GetAssignmentsSortOrder.Desc],
			pageSize: 1,
		},
		{ signal }
	);
	return data.results.at(0) ?? null;
}

export function useAssignedAssignmentQuery(participantId: string | null) {
	return useQuery({
		enabled: !!participantId,
		meta: {
			errorMessage: `Error fetching assignments for patient ID: ${participantId}`,
		},
		queryKey: [QueryKey.Assignment, participantId],
		queryFn: ({ signal }) => getAssignedAssignment(participantId, signal),
	});
}
