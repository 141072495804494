import { Trans } from 'react-i18next';

export function ordinalSuffixPerformance(n: number) {
	const lastDigit = n % 10,
		lastTwoDigits = n % 100;

	if (lastTwoDigits === 11 || lastTwoDigits === 12 || lastTwoDigits === 13) {
		return (
			<Trans
				i18nKey='web.report.uniqueResponses.performance'
				components={{ b: <b /> }}
				values={{
					value: n,
				}}
			/>
		);
	}

	if (lastDigit === 1) {
		return (
			<Trans
				i18nKey='web.report.uniqueResponses.performance_1'
				components={{ b: <b /> }}
				values={{
					value: n,
				}}
			/>
		);
	}

	if (lastDigit === 2) {
		return (
			<Trans
				i18nKey='web.report.uniqueResponses.performance_2'
				components={{ b: <b /> }}
				values={{
					value: n,
				}}
			/>
		);
	}

	if (lastDigit === 3) {
		return (
			<Trans
				i18nKey='web.report.uniqueResponses.performance_3'
				components={{ b: <b /> }}
				values={{
					value: n,
				}}
			/>
		);
	}

	return (
		<Trans
			i18nKey='web.report.uniqueResponses.performance'
			components={{ b: <b /> }}
			values={{
				value: n,
			}}
		/>
	);
}
