import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import styled, { css } from 'styled-components';

import { Report } from '../../../CognitiveEvaluation.types';

import { SemanticLayout } from './SemanticLayout';

type SematicReportProps = {
	batteryResult?: Report;
	segmentResult?: DeepSegmentResult;
};

export function SemanticReport({
	batteryResult,
	segmentResult,
}: Readonly<SematicReportProps>) {
	return (
		<SemanticReportContainer>
			<SemanticLayout
				batteryResult={batteryResult}
				segmentResult={segmentResult}
			/>
		</SemanticReportContainer>
	);
}

const SemanticReportContainer = styled.div(
	({ theme: { spacing } }) => css`
		// account for distance between layout and FooterId container
		margin-bottom: ${spacing.sm};
	`
);
