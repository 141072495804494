import { useTranslation } from 'react-i18next';

import { Trials } from 'components/mosaic/molecules/Trials';
import { theme } from 'components/providers/StyleProvider/theme';
import { ReportCardInfo } from 'components/report/AssessmentComponents/ReportCardInfo';
import { AudioPlaybackCard } from 'components/report/AudioPlaybackCard/AudioPaybackCard';
import { P2 } from 'components/shared/designSystem';
import { ScoreWithInterpretation } from '../../../../mosaic/organisms';
import { renderRequiresReviewComponent } from '../../components/InterpretationComponent';

import {
	CaptionFaded,
	Card,
	Container,
	Header,
	LineDivider,
	Row,
	TrialsContainer,
	TrialsDivider,
	Wrapper,
} from './ImmediateRecall.style';
import { TrialsResult } from './ImmediateRecall.types';
import { MAX_SCORE, createPrompt } from './ImmediateRecall.utils';

export type ImmediateRecallLayoutProps = {
	trials: TrialsResult;
	requiresReview: boolean;
};

export function ImmediateRecallLayout({
	trials,
	requiresReview,
}: Readonly<ImmediateRecallLayoutProps>) {
	const { t } = useTranslation();
	const { correctResponses, trial1, trial2 } = trials;
	const displayedScore = requiresReview
		? `--/${MAX_SCORE}`
		: correctResponses;

	return (
		<Wrapper>
			<Container>
				<Card>
					<ReportCardInfo
						title={t(
							'web.report.pvltImmediateReport.immediateRecall'
						)}
						tooltipText={t(
							'web.report.pvltImmediateReport.subTitle'
						)}
					/>
					<LineDivider />
					<Row>
						<div
							style={{
								minWidth: requiresReview ? '445px' : '',
							}}
						>
							<ScoreWithInterpretation
								score={{
									headerText: t(
										'web.report.pvltImmediateReport.correctResponsesTitle'
									),
									value: displayedScore,
								}}
								renderInterpretationText={() => (
									<P2>
										{renderRequiresReviewComponent({
											requiresReview,
										})}
									</P2>
								)}
								// Tentatively hiding extra details but we should still show 'requires review' content
								hideTextPanel={!requiresReview}
							/>
						</div>
						<TrialsContainer>
							<P2
								style={{
									color: theme.color.bodyTextSecondary,
									fontWeight: theme.weight.medium,
								}}
							>
								{t('web.report.pvltImmediateReport.breakdown')}
							</P2>
							<Trials
								responseText={
									trial1.score.includes('--')
										? t(
												'web.report.uniqueResponses.cannotCalculate'
										  )
										: t(
												'web.report.uniqueResponses.correctResponses'
										  )
								}
								score={trial1.score}
								title={t(
									'web.report.pvltImmediateReport.trials1'
								)}
							/>
							<TrialsDivider />
							<Trials
								responseText={
									trial2.score.includes('--')
										? t(
												'web.report.uniqueResponses.cannotCalculate'
										  )
										: t(
												'web.report.uniqueResponses.correctResponses'
										  )
								}
								score={trial2.score}
								title={t(
									'web.report.pvltImmediateReport.trials2'
								)}
							/>
						</TrialsContainer>
					</Row>
				</Card>
			</Container>
			<Header>{t('web.shared.recordings')}</Header>
			<Container>
				<AudioPlaybackCard
					cardTitle={t(
						'web.report.pvltImmediateReport.immediateRecall'
					)}
					renderCardContent={() => createPrompt(trial1.prompt)}
					audioSource={trial1.url}
				/>

				<AudioPlaybackCard
					cardTitle={t(
						'web.report.pvltImmediateReport.immediateRecallRepeat'
					)}
					renderCardContent={() => createPrompt(trial2.prompt)}
					audioSource={trial2.url}
				/>
			</Container>
			<CaptionFaded>{t('web.report.footer.info')}</CaptionFaded>
		</Wrapper>
	);
}
