import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';
import { DelayedRecognitionLayout } from './DelayedRecognitionLayout';
import { useDelayedRecognitionData } from './DelayedRecognitionReport.utils';

type DelayedRecognitionReportProps = {
	segmentResult: DeepSegmentResult;
};

export const DelayedRecognitionReport = ({
	segmentResult,
}: Readonly<DelayedRecognitionReportProps>) => {
	const { delayedResult, requiresReview } =
		useDelayedRecognitionData(segmentResult);
	return (
		<DelayedRecognitionLayout
			delayedResult={delayedResult}
			requiresReview={requiresReview}
		/>
	);
};
