import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';
import styled, { css } from 'styled-components';

import { EnumItem } from '../../../enums/enumeration';
import { AssignmentStatus, SortDir } from '../../../generated/graphql';
import { SortOrder } from '../../../types';

import { Header } from './Header';
import { Row } from './Row';
import { EmptyTableContent } from './emptyTableContent/EmptyTableContent';

type HeaderColumnComponentProps<T> = {
	column: TableColumn<T>;
};

export type RowColumnProps<T> = {
	column: TableColumn<T>;
	value: T[keyof T];
	row?: T;
};

export type TableColumnOption<T> = {
	key: string;
	value: string;
	requirement?: (props: ColumnOptionRequirements) => boolean;
	callback: (row: T) => void;
	operations?: OperationToken[];
};

type ColumnOptionRequirements = {
	status: AssignmentStatus | undefined;
};

export type TableColumn<T> = {
	propertyName: string;
	// TODO: Fix the issue with this and DataTables.stories.tsx
	formatProperty?: (format: RowColumnProps<T>) => T[keyof T];
	rowColumnComponent?: (format: RowColumnProps<T>) => JSX.Element | null;
	width?: string;
	minWidth?: string;
	minHeight?: string;
	headerDisplay?: string;
	headerColumnComponent?: (
		format: HeaderColumnComponentProps<T>
	) => JSX.Element;
	sortable?: boolean;
	sortDir?: SortDir;
	sortProperty?: string;
	options?: TableColumnOption<T>[];
};

export type DataTableProps<T> = {
	columns: TableColumn<T>[];
	tableData: T[];
	onSort: React.Dispatch<React.SetStateAction<SortOrder | undefined>>;
	deviantWidth?: string;
	fallbackText: string;
	longText?: string;
	noDataIcon?: EnumItem;
	isFilteringData?: boolean;
	notFoundTitle?: string;
	notFoundSubtitle?: string;
	returnToDefault?: boolean;
};

const DataTable = <T,>({
	columns,
	tableData = [],
	onSort,
	fallbackText,
	longText,
	noDataIcon,
	deviantWidth,
	isFilteringData,
	notFoundTitle = '',
	notFoundSubtitle = '',
	returnToDefault = false,
}: DataTableProps<T>): JSX.Element => {
	const emptyTableData = tableData.length === 0;

	return (
		<StyledContainer $emptyTableData={emptyTableData}>
			<Header
				columns={columns}
				onSortData={onSort}
				deviantWidth={deviantWidth}
				returnToDefault={returnToDefault}
			/>
			{emptyTableData ? (
				<EmptyTableContent
					notFoundTitle={notFoundTitle}
					notFoundSubtitle={notFoundSubtitle}
					isFilteringData={isFilteringData}
					longText={longText}
					fallbackText={fallbackText}
					noDataIcon={noDataIcon}
				/>
			) : (
				tableData.map((data, idx) => (
					<Row
						columns={columns}
						dataRow={data}
						key={idx}
						deviantWidth={deviantWidth}
					/>
				))
			)}
		</StyledContainer>
	);
};

export { DataTable };

type StyledContainerProps = {
	$emptyTableData: boolean;
};

const StyledContainer = styled.div<StyledContainerProps>(
	({ theme: { color }, $emptyTableData }) => css`
		border-bottom: ${!$emptyTableData && `1px solid ${color.tableBorder}`};
		overflow-y: visible;
		overflow-x: auto;
	`
);
