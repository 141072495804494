import {
	DeepSegmentResult,
	MetricItem,
	SegmentType,
} from '@lh/eng-platform-battery-service-rest-client';

import { useEffect, useMemo, useState } from 'react';

import { S3Segment, useFetchS3Segments } from 'utils/fetchS3Segments';
import { capitaliseText } from '../../../../../utils/stringUtils';

import { Report } from '../../../CognitiveEvaluation.types';

interface SemanticProps {
	batteryResult?: Report;
	segmentResult?: DeepSegmentResult;
}

const initialValues = {
	id: '',
	assessmentResultId: '',
	segmentId: '',
	hasProcessingError: false,
	isExpectingMetrics: false,
	isExpectingResults: false,
	hasReceivedResults: false,
	metricItems: [],
	segment: {
		displayKey: '',
		id: '',
		isExpectingMetrics: false,
		isExpectingResults: false,
		metadata: {},
		name: '',
		segmentType: SegmentType.SemanticFluency,
		audienceType: '',
	},
};

export function useSemanticData({
	batteryResult,
	segmentResult,
}: SemanticProps) {
	let filesToFetch: S3Segment[] = [];
	let _segmentResult: DeepSegmentResult = initialValues;

	const [recordingUrl, setRecordingUrl] = useState('');

	if (batteryResult) {
		if (batteryResult.batteryResultById) {
			const { assessmentResults } = batteryResult.batteryResultById;

			assessmentResults.forEach((assessmentResult) => {
				const { segmentResults } = assessmentResult;

				segmentResults.forEach((segmentResult) => {
					if (
						segmentResult.segment.segmentType ===
						SegmentType.SemanticFluency
					) {
						_segmentResult = segmentResult;
					}
				});
			});
		}
	}

	const {
		audio,
		breakdownScores,
		correctResponses,
		percentile,
		englishSegment,
	} = useMemo(() => {
		if (_segmentResult) {
			return parseSemanticMetrics(_segmentResult.metricItems);
		}

		return parseSemanticMetrics(segmentResult?.metricItems);
	}, [_segmentResult?.metricItems.length, segmentResult?.metricItems.length]);

	filesToFetch = useMemo(() => {
		if (_segmentResult.id) {
			return buildFilesToFetch(_segmentResult.id);
		}

		if (segmentResult?.id) {
			return buildFilesToFetch(segmentResult.id);
		}

		return [];
	}, [_segmentResult?.id, segmentResult?.id]);

	const files = useFetchS3Segments({
		segments: filesToFetch,
		blobType: 'audio/mp4',
	});

	useEffect(() => {
		files.forEach((file) => {
			if (!file.responseUrl) return;
			setRecordingUrl(file.responseUrl);
		});
	}, [files]);

	return {
		audio,
		breakdownScores,
		correctResponses,
		englishSegment,
		percentile,
		recordingUrl,
	};
}

export type Audio = {
	prompt: string;
	recordingDuration?: number;
	url: string;
};

export type BreakdownScores = {
	epoch1: string;
	epoch2: string;
	epoch3: string;
	epoch4: string;
};

enum SemanticKeys {
	Category = 'category',
	Epoch1 = 'epoch1_score',
	Epoch2 = 'epoch2_score',
	Epoch3 = 'epoch3_score',
	Epoch4 = 'epoch4_score',
	LanguageCode = 'language_code',
	Percentile = 'semantic_fluency_percentile',
	RecordingDuration = 'recording_duration',
	TotalScore = 'total_score',
}

export function parseSemanticMetrics(metricItems?: MetricItem[]) {
	const sematicAudio: Audio = { prompt: '', recordingDuration: 0, url: '' };
	const semanticBreakdownScores: BreakdownScores = {
		epoch1: '--',
		epoch2: '--',
		epoch3: '--',
		epoch4: '--',
	};
	let semanticCorrectResponses = '-1';
	let semanticPercentile: number | undefined;
	let englishSegment = true;

	metricItems?.forEach((metricItem) => {
		if (metricItem.key.includes(SemanticKeys.Epoch1)) {
			semanticBreakdownScores.epoch1 = metricItem.value
				? metricItem.value.toString()
				: '--';
		}

		if (metricItem.key.includes(SemanticKeys.Epoch2)) {
			semanticBreakdownScores.epoch2 = metricItem.value
				? metricItem.value.toString()
				: '--';
		}

		if (metricItem.key.includes(SemanticKeys.Epoch3)) {
			semanticBreakdownScores.epoch3 = metricItem.value
				? metricItem.value.toString()
				: '--';
		}

		if (metricItem.key.includes(SemanticKeys.Epoch4)) {
			semanticBreakdownScores.epoch4 = metricItem.value
				? metricItem.value.toString()
				: '--';
		}

		if (metricItem.key.includes(SemanticKeys.Category)) {
			sematicAudio.prompt = capitaliseText(metricItem.value);
		}

		if (metricItem.key.includes(SemanticKeys.RecordingDuration)) {
			sematicAudio.recordingDuration = Number(metricItem.value);
		}

		if (metricItem.key.includes(SemanticKeys.TotalScore)) {
			semanticCorrectResponses = isNaN(Number(metricItem.value))
				? '-1'
				: metricItem.value;
		}

		if (metricItem.key === SemanticKeys.Percentile) {
			semanticPercentile = isNaN(Number(metricItem.value))
				? undefined
				: Number(metricItem.value);
		}

		if (metricItem.key === SemanticKeys.LanguageCode) {
			englishSegment =
				metricItem.value === 'en_US' || metricItem.value === 'en_GB';
		}
	});

	return {
		audio: sematicAudio,
		breakdownScores: semanticBreakdownScores,
		correctResponses: semanticCorrectResponses,
		percentile: semanticPercentile,
		englishSegment: englishSegment,
	};
}

export const buildFilesToFetch = (segmentResultId: string): S3Segment[] => {
	return [
		{
			segmentResultId,
			type: '',
			filename: 'semantic_fluency_audio.mp4',
		},
		{
			segmentResultId,
			type: '',
			filename: 'semantic_fluency_audio.webm',
		},
	];
};
