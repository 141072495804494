import { ScoreTag } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';

import { ReportInfoPanel } from 'components/report/AssessmentComponents/ReportCardInfo';
import { QnrRowData } from 'components/report/CustomQuestionnaire.utils';
import { FastTable } from '../Table';

import { FASTScoringDescription } from './FASTScoringDescription';
import { Card, Container, ScoreContainer, Scorer } from './FastLayout.style';

type FastLayoutProps = {
	score: {
		value: string;
		tagText: string;
	};
	tableData: QnrRowData[];
};

export function FastLayout({ score, tableData }: Readonly<FastLayoutProps>) {
	const { t } = useTranslation();

	return (
		<Container>
			<ReportInfoPanel
				title={t('web.report.fastReport.fastReport')}
				tooltipText={t('web.report.fastReport.fastTooltip')}
			>
				<ScoreContainer loading={false}>
					<Scorer>
						<ScoreTag
							variant='small'
							score={score.value}
							roundedBorders
						/>
						<FASTScoringDescription text={score.tagText} />
					</Scorer>
				</ScoreContainer>
			</ReportInfoPanel>
			{tableData.length > 0 && (
				<Card>
					<FastTable data={tableData} />
				</Card>
			)}
		</Container>
	);
}
