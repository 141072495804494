import styled from 'styled-components';

import { InterpretationText, ScoreTag } from '@lh/eng-web-mosaic-common';

type ScoreWithInterpretationProps = {
	score: {
		headerText: string;
		value: string;
	};
	renderInterpretationText: () => JSX.Element;
	hideTextPanel?: boolean;
};

const ScoreWithInterpretation = ({
	score,
	renderInterpretationText,
	hideTextPanel = false,
}: ScoreWithInterpretationProps) => {
	return (
		<Container data-testid='score-with-interpretation'>
			<ScoreTag
				title={score.headerText}
				score={score.value}
				roundedBorders={hideTextPanel}
			/>
			{!hideTextPanel ? (
				<InterpretationText
					renderInterpretationText={renderInterpretationText}
				/>
			) : null}
		</Container>
	);
};

export { ScoreWithInterpretation };

const Container = styled.div`
	display: flex;
	flex-direction: 'row';
`;
