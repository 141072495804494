import { Reason } from '@lh/eng-platform-organization-service-rest-client';
import {
	Handedness,
	Patient,
	SexAssignedAtBirth,
} from '@lh/eng-platform-subject-service-rest-client';

import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import {
	useCreateAssignmentMutation,
	useSaveTestingReasonMutation,
} from 'api/assignment';
import { useBatteriesQuery } from 'api/battery';
import { getPatient, useUpdatePatientMutation } from 'api/patient';
import { useSendLink } from 'api/remote-assessments';
import { QueryKey } from 'api/query';
import { useProvidersInterpretingResults } from 'components/shared/hooks';
import { useOrganizationFeatures } from 'components/shared/hooks/useOrganizationFeatures';
import { useModalStore } from 'store';
import { messageEnum, MessageEnumItem } from '../../enums/messageEnum';
import { FeatureType } from '../../generated/graphql';
import { ERROR } from '../../logging/linusLogger';
import { DropdownOption } from '../../types';
import { UserContext } from '../context/UserContext';
import { ButtonSm } from '../shared/designSystem/ButtonSm';
import { messages } from '../shared/errorMessages';
import { LinusInput } from '../shared/Forms/Components/LinusInput';
import {
	addBatteryModel,
	addBatterySchema,
	getModel,
} from '../shared/Forms/Schemas/addBatterySchema';
import { InfoMessage } from '../shared/InfoMessage';

import { AssessmentConfiguration } from './AssessmentConfiguration';
import {
	batteryTypeOptions,
	CONTACT_PREFERENCES,
	LOCATION_BATTERY_TYPE,
} from './patient.helpers';
import { ReasonsForTesting } from './ReasonsForTesting';

export type CustomPatient = Patient & { newPatient: boolean };

export type AddBatteryForPatientProps = {
	patient: CustomPatient;
	isTest?: boolean;
	onCancel: () => void;
	renderProps?: () => JSX.Element;
};

export function AddBatteryForPatient({
	patient,
	onCancel,
	renderProps,
	isTest = false,
}: Readonly<AddBatteryForPatientProps>) {
	const { currentUser } = useContext(UserContext);
	const client = useQueryClient();
	const features = useOrganizationFeatures();
	const { t } = useTranslation();

	const {
		mutateAsync: createAssignment,
		isPending: loading,
		error,
	} = useCreateAssignmentMutation();
	const { data: batteries } = useBatteriesQuery(currentUser.organizationId);
	const { mutateAsync: updatePatient } = useUpdatePatientMutation();
	const { mutateAsync: sendLink } = useSendLink();
	const { mutateAsync: saveTestingReason } = useSaveTestingReasonMutation();

	const openModal = useModalStore((state) => state.open);

	const [reasons, setReasons] = useState<Reason[]>([]);
	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();

	const hasReasonsForTestingFlag = features.includes(
		FeatureType.ReasonsForTesting
	);
	const hasRemoteAssessmentFlag = features.includes(
		FeatureType.RemoteAssessment
	);

	if (error) {
		setServerSideMessage(messageEnum.error(messages.mutationHookError));
	}

	const clinicBatteriesOptions: DropdownOption[] = [];
	const remoteBatteriesOptions: DropdownOption[] = [];

	if (batteries) {
		batteries?.forEach((battery) => {
			if (battery.mobileEnabled)
				clinicBatteriesOptions?.push({
					display: t(`${battery?.displayKey}`),
					value: `${battery?.id}`,
				});
			if (battery.webEnabled)
				remoteBatteriesOptions.push({
					display: t(`${battery?.displayKey}`),
					value: `${battery?.id}`,
				});
		});
	}

	//Get list of providers and pass them into the dropdown options
	const providerOptions = useProvidersInterpretingResults();

	const onSubmit = async (
		values: addBatteryModel,
		{ setSubmitting }: FormikHelpers<addBatteryModel>
	) => {
		const isRemote =
			(!!values.contactMethod &&
				values.type === LOCATION_BATTERY_TYPE.REMOTE) ??
			false;

		if (isRemote) {
			const patientData = await client.fetchQuery({
				queryKey: [
					QueryKey.Patient,
					patient.id,
					currentUser.organizationId,
				],
				queryFn: () =>
					getPatient(patient.id, currentUser.organizationId),
				staleTime: Infinity,
			});

			if (patientData) {
				try {
					const updatePatientResponse = await updatePatient({
						organizationId: currentUser.organizationId,
						subjectId: patient.id,
						updatePatientInput: {
							language: patientData.language,
							firstName: patientData.firstName,
							lastName: patientData.lastName,
							handedness: patientData.handedness as Handedness,
							birthDate: patientData.birthDate,
							sexAssignedAtBirth:
								patientData.sexAssignedAtBirth as SexAssignedAtBirth,
							educationIds: patientData.education?.map(
								(e) => e.id
							),
							contactPreference:
								values.contactMethod ?? undefined,
							contactEmail:
								values.contactMethod !==
									CONTACT_PREFERENCES.PHONE_ONLY &&
								values.contactEmail
									? values.contactEmail
									: undefined,
							contactPhone:
								values.contactMethod !==
									CONTACT_PREFERENCES.EMAIL_ONLY &&
								values.contactPhone
									? values.contactPhone.replace(/-/g, '')
									: undefined,
						},
					});
					if (!updatePatientResponse) {
						setServerSideMessage(
							messageEnum.error(messages.mutationPayloadError)
						);
						setSubmitting(false);
						return;
					}
				} catch (err) {
					ERROR(
						`Error trying to update patient from AddBatteryForPatient, patientId: ${patient.id}, organizationId: ${currentUser.organizationId}`,
						err
					);
					setServerSideMessage(
						messageEnum.error(messages.mutationPayloadError)
					);
					setSubmitting(false);
					return;
				}
			}
		}

		try {
			const result = await createAssignment({
				createAssignmentInputV2: {
					participantId: patient.id,
					batteryId:
						values.type === LOCATION_BATTERY_TYPE.REMOTE
							? values.remoteBattery
							: values.battery,
					interpretingUserId: values.provider,
					organizationId: currentUser.organizationId,
					remote: isRemote ?? undefined,
				},
			});

			if (result?.id) {
				await saveTestingReason({
					assignmentId: result.id,
					testingReason: {
						codes: reasons.map((element) => element.diagnosisCode),
						note: values.reasonsForTestingNote,
					},
				});
			}
			if (result?.id && isRemote) {
				await sendLink({
					organizationId: currentUser.organizationId,
					assignmentId: result?.id,
				});
			}
			if (!result) {
				setServerSideMessage(
					messageEnum.error(messages.mutationPayloadError)
				);
				setSubmitting(false);
				return;
			}
		} catch (err) {
			ERROR(
				`Error trying to createAssignment or save reason for testing from AddBatteryForPatient, patientId: ${patient.id}, organizationId: ${currentUser.organizationId}, batteryId: ${values.battery}: `,
				err
			);
			setServerSideMessage(
				messageEnum.error(messages.mutationPayloadError)
			);
			setSubmitting(false);
			return;
		}

		sendEventData({
			eventType: AnalyticsAction.AssignedBattery,
			eventProperties: {
				batteryId: values.battery,
				interpretingUserId: values.provider,
			},
		});

		openModal('BatteryAssignmentConfirmation', {
			firstName: patient?.firstName,
			lastName: patient?.lastName,
			type: values.type,
		});

		setSubmitting(false);

		onCancel();
	};

	return (
		<Formik
			initialValues={getModel(patient)}
			validationSchema={addBatterySchema({
				hasRemoteAssessmentFlag,
				isTest,
			})}
			onSubmit={onSubmit}
		>
			{({ isSubmitting, isValid, dirty, values }) => {
				return (
					<>
						{renderProps && renderProps()}
						<StyledForm>
							<StyledRow newPatient={patient.newPatient}>
								<LinusInput
									name='provider'
									type='typeAhead'
									label={t`web.shared.interpretingUser`}
									dropdownOptions={providerOptions}
									width='100%'
									data-id='provider-input'
								/>
								{hasRemoteAssessmentFlag && (
									<LinusInput
										name='type'
										type='select'
										label={t(
											'web.patients.forms.remoteAssessment.type.label'
										)}
										dropdownOptions={batteryTypeOptions}
										width='100%'
										data-id='type-select'
									/>
								)}
							</StyledRow>
							{!hasRemoteAssessmentFlag && (
								<StyledRow>
									<LinusInput
										name='battery'
										type='select'
										label={t`web.shared.battery`}
										dropdownOptions={clinicBatteriesOptions}
										width='100%'
										data-id='battery-input'
										styles={{
											marginTop: '12px',
										}}
									/>
								</StyledRow>
							)}
							{values.type && (
								<div
									style={{
										marginTop: 8,
									}}
								>
									<AssessmentConfiguration
										values={values}
										type={values.type}
										hasInitialValue={
											!!patient.contactEmail ||
											!!patient.contactPhone
										}
										batteriesOptions={
											clinicBatteriesOptions
										}
										remoteBatteriesOptions={
											remoteBatteriesOptions
										}
									/>
								</div>
							)}
							{hasReasonsForTestingFlag && (
								<ReasonsForTesting
									containerStyle={{
										marginTop: hasRemoteAssessmentFlag
											? 24
											: 8,
										marginBottom: 12,
									}}
									setReasons={setReasons}
								/>
							)}
							<InfoMessage
								messageEnum={serverSideMessage}
								showIf={!!serverSideMessage}
							/>
							<StyledActionRow>
								<ButtonSm
									text={t`web.shared.close`}
									width='158px'
									onClick={onCancel}
									dataId='add-battery-cancel-button'
								/>
								<StyledSpacer />
								<ButtonSm
									disabled={
										!(isValid && dirty) || isSubmitting
									}
									text={t`web.patients.forms.assignBattery`}
									loading={loading}
									type='submit'
									width='158px'
									primary
									dataId='add-battery-submit-button'
									dataTestId='add-battery-submit-button'
								/>
							</StyledActionRow>
						</StyledForm>
					</>
				);
			}}
		</Formik>
	);
}

const StyledForm = styled(Form)`
	min-width: 548px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;
const StyledRow = styled.div<{
	newPatient?: boolean;
}>(
	({ newPatient, theme: { spacing } }) => css`
		display: flex;
		justify-content: space-between;
		gap: ${spacing.lg};

		&:first-of-type {
			margin-top: ${spacing.md};
		}

		.typeaheadselect_styled_list_provider {
			${!newPatient ? 'max-height: 100px;' : ''}
		}
	`
);
const StyledActionRow = styled.div`
	display: flex;
	justify-content: center;
	padding: 20px 0;
	flex-grow: 1;
	align-items: flex-end;
`;
const StyledSpacer = styled.span(
	({ theme: { spacing } }) => `
	width: ${spacing.xl};
`
);
