import styled, { css } from 'styled-components';

import { StyledCard } from 'components/shared/StyledCard';
import { Caption, P2 } from 'components/shared/designSystem';

export const FluencyLayoutContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.lg};
	`
);

export const Card = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		height: auto;
		min-width: 500px;
		padding: ${spacing.lg};
	`
);

export const Header = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

export const ScoreBreakdownContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
		width: 100%;
	`
);

export const ScoresWrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.md};
	`
);

export const BreakdownTitle = styled(P2)(
	({ theme: { color, weight } }) => css`
		font-weight: ${weight.medium};
		color: ${color.bodyTextSecondary};
	`
);

export const RecordingsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const CaptionFaded = styled(Caption)(
	({ theme: { color, spacing } }) => css`
		color: ${color.bodyTextSecondary};
		margin-bottom: ${spacing.sm};
	`
);
