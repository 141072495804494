import { ScoreTag, Typography } from '@lh/eng-web-mosaic-common';

import { Trans, useTranslation } from 'react-i18next';

import { ReportInfoPanel } from 'components/report/AssessmentComponents/ReportCardInfo';

import { Container, ScoreContainer, Scorer } from './RCRLayout.style';

type Props = {
	description: string;
	title: string;
	tooltipText?: string;
};

export function RCRLayout({
	description,
	title,
	tooltipText,
}: Readonly<Props>) {
	const { t } = useTranslation();
	return (
		<Container>
			<ReportInfoPanel
				title={t(title)}
				tooltipText={tooltipText ? t(tooltipText) : undefined}
			>
				<ScoreContainer loading={false}>
					<Scorer>
						<ScoreTag variant='small' score='--' roundedBorders />
						<Typography.H4 weight='400'>
							<Trans
								defaults={t(description)}
								components={{
									b: <strong />,
								}}
							/>
						</Typography.H4>
					</Scorer>
				</ScoreContainer>
			</ReportInfoPanel>
		</Container>
	);
}
