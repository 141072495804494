import { useTranslation } from 'react-i18next';

import { ReportCardInfo } from 'components/report/AssessmentComponents/ReportCardInfo';
import { AudioWidget } from 'components/shared/AudioWidget';
import { LineDivider } from 'components/shared/LineDivider';
import { H4, P1 } from 'components/shared/designSystem';
import { BreakdownScores } from '../BdstReport.types';
import { ScoreChip } from '../ScoreChip';
import { BDSTTable } from '../Table';

import {
	AudioContainer,
	AudioWidgetWrapper,
	BdstLayoutContainer,
	BdstLayoutScores,
	CaptionFaded,
	P2Med,
	RecordingsWrapper,
	ScoresWrapper,
} from './BdstLayout.style';

export type BdstLayoutProps = {
	correctResponses?: string;
	quartile?: string;
	breakdownScores: BreakdownScores;
	urls: {
		trialOne: string;
		trialTwo: string;
		trialThree: string;
	};
};

export function BdstLayout({
	breakdownScores,
	correctResponses,
	quartile,
	urls,
}: Readonly<BdstLayoutProps>) {
	const { t } = useTranslation();
	const displayedScore = correctResponses ?? '--/15';

	return (
		<BdstLayoutContainer>
			<BdstLayoutScores>
				<ReportCardInfo
					title={t('web.report.bdstReport.backwardsDigitSpan')}
					tooltipText={t('web.report.bdstReport.subtitle')}
				/>
				<LineDivider />
				<ScoresWrapper>
					<ScoreChip
						score={displayedScore}
						quartile={quartile}
						requiresReview={!correctResponses}
					/>
					<BDSTTable
						trialOne={{
							any: breakdownScores.trialOne.score.any,
							serial: breakdownScores.trialOne.score.serial,
						}}
						trialTwo={{
							any: breakdownScores.trialTwo.score.any,
							serial: breakdownScores.trialTwo.score.serial,
						}}
						trialThree={{
							any: breakdownScores.trialThree.score.any,
							serial: breakdownScores.trialThree.score.serial,
						}}
					/>
				</ScoresWrapper>
			</BdstLayoutScores>
			<Recordings urls={urls} breakdownScores={breakdownScores} />
			<CaptionFaded>{t('web.report.footer.info')}</CaptionFaded>
		</BdstLayoutContainer>
	);
}

type RecordingsProps = {
	breakdownScores: BdstLayoutProps['breakdownScores'];
	urls: BdstLayoutProps['urls'];
};

function Recordings({ breakdownScores, urls }: Readonly<RecordingsProps>) {
	const { t } = useTranslation();

	return (
		<RecordingsWrapper>
			<H4
				style={{
					marginLeft: '24px',
					fontWeight: 500,
				}}
			>
				{t('web.shared.recordings')}
			</H4>
			<AudioContainer>
				<AudioWidgetWrapper
					style={{
						gridColumn: '1/2',
						gridRow: '1/2',
						display: 'flex',
						flexDirection: 'column',
						gap: '12px',
					}}
				>
					<div>
						<P1
							style={{
								fontWeight: 600,
							}}
						>
							{t('web.report.bdstReport.trial_1')}
							{': '}
							{t('web.report.bdstReport.backwardsDigitSpan')}
						</P1>
						<P2Med
							style={{
								marginTop: '2px',
							}}
						>
							{t('web.shared.prompt')}
							{': '}
							{breakdownScores.trialOne.audio.prompt}
						</P2Med>
						<P2Med>
							{t('web.shared.backwards')}
							{': '}
							{breakdownScores.trialOne.audio.backwards}
						</P2Med>
					</div>
					<AudioWidget srcUrl={urls.trialOne} />
				</AudioWidgetWrapper>
				<AudioWidgetWrapper
					style={{
						gridColumn: '2/2',
						gridRow: '1/2',
						display: 'flex',
						flexDirection: 'column',

						gap: '12px',
					}}
				>
					<div>
						<P1
							style={{
								fontWeight: 600,
							}}
						>
							{t('web.report.bdstReport.trial_2')}
							{': '}
							{t('web.report.bdstReport.backwardsDigitSpan')}
						</P1>
						<P2Med
							style={{
								marginTop: '2px',
							}}
						>
							{t('web.shared.prompt')}
							{': '}
							{breakdownScores.trialTwo.audio.prompt}
						</P2Med>
						<P2Med>
							{t('web.shared.backwards')}
							{': '}
							{breakdownScores.trialTwo.audio.backwards}
						</P2Med>
					</div>
					<AudioWidget srcUrl={urls.trialTwo} />
				</AudioWidgetWrapper>
				<AudioWidgetWrapper
					style={{
						gridColumn: '1/2',
						gridRow: '2/3',
						display: 'flex',
						flexDirection: 'column',
						gap: '12px',
					}}
				>
					<div>
						<P1
							style={{
								fontWeight: 600,
							}}
						>
							{t('web.report.bdstReport.trial_3')}
							{': '}
							{t('web.report.bdstReport.backwardsDigitSpan')}
						</P1>
						<P2Med
							style={{
								marginTop: '2px',
							}}
						>
							{t('web.shared.prompt')}
							{': '}
							{breakdownScores.trialThree.audio?.prompt}
						</P2Med>
						<P2Med>
							{t('web.shared.backwards')}
							{': '}
							{breakdownScores.trialThree.audio?.backwards}
						</P2Med>
					</div>
					<AudioWidget srcUrl={urls.trialThree} />
				</AudioWidgetWrapper>
			</AudioContainer>
		</RecordingsWrapper>
	);
}
