import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';
import { useMemo } from 'react';
import { S3Segment, useFetchS3Segments } from 'utils/fetchS3Segments';
import { AnswerResponse, DelayedRecognition } from './DelayedRecognition.types';

export const MAX_SCORE = '6';

export const useDelayedRecognitionData = (segmentResult: DeepSegmentResult) => {
	const { delayedResult, requiresReview } = useMemo(() => {
		return parseDelayedRecognitionMetrics(segmentResult);
	}, [segmentResult.metricItems.length]);

	const filesToFetch = useMemo(() => {
		return buildFilesToFetch(segmentResult.id);
	}, [segmentResult.id]);

	const s3Segments = useFetchS3Segments({
		segments: filesToFetch,
		blobType: 'json',
	});

	s3Segments.forEach((file) => {
		if (file.type === DelayedRecognitionFilenames.DelayedRecognition) {
			delayedResult.answers = (file?.data?.data ||
				[]) as AnswerResponse[];
		}
	});
	return { delayedResult, requiresReview };
};

export const parseDelayedRecognitionMetrics = (
	segmentResult: DeepSegmentResult
) => {
	const delayedResult: DelayedRecognition = {
		correctResponses: '',
		answers: [],
	};

	let trialScore = 0;

	segmentResult.metricItems.forEach((metricItem) => {
		if (metricItem.key.includes('pvlt_recognition_score')) {
			trialScore = Number(metricItem.value);
		}
	});

	const requiresReview = isNaN(trialScore);
	delayedResult.correctResponses = requiresReview
		? `--/${MAX_SCORE}`
		: `${trialScore}/${MAX_SCORE}`;

	return { delayedResult, requiresReview };
};

export enum DelayedRecognitionFilenames {
	DelayedRecognition = 'delayed-recognition',
}

const processQuestionnaires = async (response: Blob) => {
	const responseText = await response.text();
	return JSON.parse(responseText);
};

export const buildFilesToFetch = (segmentResultId: string): S3Segment[] => {
	return [
		{
			segmentResultId,
			type: DelayedRecognitionFilenames.DelayedRecognition,
			filename: 'delayed-recognition.json',
			processData: processQuestionnaires,
		},
	];
};
